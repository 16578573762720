@import '../../reusable_cmp_lib/colorVariables.scss';

.enrollmentFullWidthLeft {
  width: 90%;
  text-align: left;
  display: inline-block;
}

.leftIcon {
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  padding-left: 34px;
  min-height: 24px;
  line-height: 24px;
  margin-bottom: 0rem;
  margin-top: 2rem;
}

.iconBreak {
  background-image: url('../../../assets/selfEnrollment/icons/brakes.svg');
}

.iconNighttime {
  background-image: url('../../../assets/selfEnrollment/icons/nighttime-driving.svg');
}

.iconIdle {
  background-image: url('../../../assets/selfEnrollment/icons/idle.svg');
}

.iconSpeed {
  background-image: url('../../../assets/selfEnrollment/icons/speed.svg');
}

.ubiHilights {
  background-color: $primary_color;
  min-height: 200px;
  color: #fff;
  text-align: left;
}

.ubiPhoneScreen {
  background-image: url('../../../assets/selfEnrollment/images/drive_view_phone.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  width: 100%;
  height: 600px;
  max-height: 600px;
  position: absolute;
}

.ubiTerms {
  text-decoration-line: underline;
  text-underline-offset: 10px;
}

.discountedInsuranceRates {
  font-size: 12px;
  color: $popups_pearl_color;
}

.disableDataShare {
  opacity: 0.5;
  pointer-events: none;
}

.radioDescText {
  font-size: 20px;
  color: $carnet_root_color;
}

.driviewTermsText {
  color: $background_color;
}

.fleetModalHeader {
  font-size: 26px;
}

@media only screen and (max-width: 765px) {
  .ubiPhoneScreen {
    height: 500px;
    background-position: center;
    position: relative;
  }
}

.conciergePhoneScreen {
  background-image: url('../../../assets/selfEnrollment/images/concierge_phone.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 424px;
}

.conciergeAssistance {
  background-image: url('../../../assets/selfEnrollment/images/concierge_assistance.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  height: 100%;
  min-height: 270px;
  max-height: 336px;
}

.iconAssistance {
  background-image: url('../../../assets/selfEnrollment/icons/service_assistance.svg');
  line-height: 40px;
  padding-left: 50px;
  background-size: 40px;
  background-position: top left;
}

.iconScheduling {
  background-image: url('../../../assets/selfEnrollment/icons/schedule.svg');
  line-height: 40px;
  padding-left: 50px;
}

.divLine {
  width: 2px;
  height: 114px;
  border: solid 1px #0078c3;
  display: none;
}

.modal-tos {
  max-width: 960px;
}

.modal-tos .tos-body {
  display: block;
  width: 96%;
  padding: 0 20px 0 30px;
  max-height: 500px;
  overflow: scroll;
}

.tos-cont-btn-wrapper {
  width: 100%;
  text-align: center;
}

.tos_subhead_instrux {
  display: inline-block;
  width: 100%;
  font-size: 0.8em;
  color: $text_label_dark_color;
  margin-bottom: 20px;
}

.modal-dialog.carnet-sg-modal.tosDeclinationConfirmation {
  .modal-title {
    color: #fff;
    font-size: 2em;
  }
}

.modal-dialog.carnet-sg-modal.tosDeclinationConfirmation .modal-content .modal-footer {
  width: 100%;

  div {
    width: 100%;
    margin: 10px 0 0 0;
    padding: 0;
    text-align: center;
  }

  button.modal-primary-btn.btn.btn-modal_background_color,
  button.modal-secondary-btn.btn.btn-modal_background_color {
    width: 80% !important;
    margin: auto;
    display: block;
  }
}

.tosContainerCA {
  height: 250px;
  max-height: 250px;
  min-height: 250px;
  font-size: 0.5em;
  background-color: $article_background_color;
  padding: 10px 0 10px 10px;
  border-bottom: 0.5px solid $text_input_border_color;
  margin-bottom: 20px;

  .innerWrapper {
    height: 220px;
    max-height: 220px;
    min-height: 220px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: none;
    padding: 0 10px 0 0;
    margin: 0;
  }
}

.selfEnrollmentRegCodeModal {
  width: 350px;

  .noBottomBorder {
    border-bottom: none;
  }

  .noTopBorder {
    border-top: none;
  }

  button {
    width: 100%;
  }
}

label.maxWidth300 {
  max-width: 300px;
}

.tosDataTrial {
  .hrLine {
    border: none;
    height: 2px;
    background: $inputbox_border;
  }

  .maybeLaterBtn {
    margin-right: 32px;
  }
}
