@import '../../reusable_cmp_lib/colorVariables.scss';
@import '../faq/Faq.scss';
.styles_faq-row-wrapper__3Hsch {
  padding: 64px 160px 90px 160px;
}

.styles_faq-row-wrapper__3Hsch .styles_row-body__8wIE9 .styles_faq-row__2Rd2Y .styles_row-title__2yqnt {
  padding: 25px 0;
}

.faqCAheader {
  font-size: 16px;
  font-family: 'VWTextBold';
}
.mdlyr {
  font-family: 'VWTextBold';
}

.faq-row.styles_faq-row__2Rd2Y {
  padding: 12px;
}
.underline {
  text-decoration: underline;
}

.goback {
  text-align: left;
  margin-bottom: -17px;
  &:hover {
    cursor: pointer;
  }
  .arrow {
    font-size: 20px;
    color: $carnet_root_color;
  }
  .back {
    font-size: 15px;
    font-family: VWText;
    color: $carnet_root_color;
    margin-left: 6px;
  }
}
.statictoggle {
  display: none;
}
.back {
  margin-left: 10px;
}

.account-faq-disclaimers {
  font-family: 'VWText';
  font-size: 9px;
  margin-left: 5em;
  margin-right: 20em;
  margin-top: 7em;
  .faq-disclaimer {
    margin-bottom: 2em;
    span.citation-superscript {
      vertical-align: super;
      font-weight: 900;
      font-size: 12px;
    }
  }
}

.viewFaqLink {
  float: right;
  margin-right: 34px;
}
.viewFaqText {
  height: 16px;
  font-family: VWText;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: $link_text_light_color;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

@media only screen and (max-width: 769px) {
  .styles_faq-row-wrapper__3Hsch {
    padding: 0px 35px 90px !important;
  }

  .logInOut {
    float: right;
    margin: 0px 0px 0 0;
    padding: 0;
    width: auto;
    min-width: 60px;
    text-align: left;
    cursor: pointer;
  }
}
