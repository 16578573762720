.checkout {
  .border-bottom-active {
    border-bottom: 2px solid $button_primary_border;
  }

  label.cvv {
    margin: 0.5rem auto 0rem 1.75rem;
    font-size: 0.75rem;
  }

  input.cvv {
    max-width: 100px;
    margin: 0px auto 2rem 1.75rem;
  }

  .price-total-border-top {
    border-color: $link_text_light_color !important;
  }

  #cwPaymentFormDiv {
    min-height: 400px;
  }

  #newAddressForm .form-group {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  #newAddressForm input,
  #newAddressForm select {
    margin-left: 0px;
    min-width: 300px;
  }

  .stripeFormWrapper {
    margin-top: 50px;
  }
}
