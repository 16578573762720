.radioCwpOverwriteWrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin-top: 5px;
  cursor: pointer;
  background-color: #fff;
  &:first-child {
    margin-top: 10px;
  }

  .radioCwpOverwriteLabel {
    font-family: inherit;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    color: #000;
    padding-left: 40px;
    margin-left: -40px;
    z-index: 1;
  }

  input[type='radio'].radioCwpOverwrite {
    appearance: none;
    color: #001e50;
    width: 18px;
    height: 18px;
    border: 2px solid #001e50;
    border-radius: 80%;
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      cursor: default;
    }

    &::before {
      content: '';
      padding: 0;
      margin: 0;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      top: -5px;
      left: 1px;
      position: relative;
      color: #fff;
      background-color: none;
      display: inline-block;
      visibility: visible;
      border: 0.5px solid white;
    }
    &:checked::before {
      content: '';
      padding: 0;
      margin: 0;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      top: -5px;
      left: 1px;
      position: relative;
      background-color: #001e50;

      display: inline-block;
      visibility: visible;
      border: 0.5px solid white;
    }
  }
}
