@import '../colorVariables.scss';

.btn {
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: large;
}

.carnet-sg-modal {
  background-color: transparent;

  .modal-content {
    color: white;
    background-color: $modal_background_color;
    font-weight: normal;
    font-size: 14pt;
    padding: 20px 16px;
  }
  .modal-header {
    color: #fff;
  }

  .modal-primary-btn {
    background-color: white;
    color: $modal_background_color;
    border-radius: 24px;
    border: none;
    max-width: 400px;
    font-size: 1rem;
    padding: 8px;
  }

  .modal-secondary-btn {
    background-color: $modal_background_color;
    color: white;
    border-radius: 24px;
    border: 2px solid white;
    max-width: 400px;
    font-size: 1rem;
    padding: 8px;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    border-color: $modal_background_color;
  }
}
