@import '../reusable_cmp_lib/colorVariables.scss';

body {
  background-color: $background_color;
  color: $carnet_root_color;
}

.App {
  text-align: center;
}

.preVehicleAuth {
  min-height: calc(100vh - 90px);
  h1 {
    font-size: 2rem;
  }
}

.cwpContentHeightMin {
  min-height: calc(100vh - 234px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cwp-header {
  #btnCarnetHome {
    width: 143px;
    cursor: pointer;
    float: left;
    margin-left: 48px;
    margin-top: 24px;
  }

  #vwlogo {
    background-color: $background_color;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    background-image: url(../../assets/images/vwlogo.svg);
    background-size: 48px;
    background-position: center;
    background-repeat: no-repeat;
    width: 68px;
    height: 68px;
    position: absolute;
    right: 33%;
    top: 40px;
  }

  hr {
    border: 1px solid $carnet_root_color;
  }
}

.App-link {
  color: $carnet_root_color;
}

.cwp-blue {
  color: $carnet_root_color;
  cursor: pointer;
}

.cwp-lagacy-blue {
  color: $link_text_color;
  cursor: pointer;
}

.pageSubTitle {
  width: 80%;
  display: inline-block;
  text-align: left;
  font-size: 16px;
  margin: 0 auto;
}

.preVehicleAuth span.arrowRight {
  background-image: url(../../assets/images/arrow-right.svg);
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 4px;
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  padding-left: 29px;
}

input.cwp {
  max-width: 300px;
  margin: auto;
}

input[type='text'].cwp,
input[type='number'].cwp,
input[type='password'].cwp,
input[type='secret'].cwp,
input[type='email'].cwp,
input.cwpEmail,
input[type='tel'].cwp,
input[type='date'].cwp,
select.cwp {
  box-shadow: none;
  border-radius: 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $inputbox_border;
  padding-left: 0px;
  width: 100%;
  max-width: 300px;
  background-repeat: no-repeat;
  background-position: calc(100% - 6px);
  padding-right: 30px;
}

input[type='text'].cwp:focus,
input[type='password'].cwp:focus,
input[type='secret'].cwp:focus,
input[type='email'].cwp:focus,
input.cwpEmail:focus,
input[type='tel'].cwp:focus,
input[type='date'].cwp:focus,
select.cwp:focus {
  outline: none;
  border-bottom: 1px solid $inputbox_border_focused;
}

input:invalid,
select:invalid {
  outline: none;
}

input.submitted:invalid,
select.submitted:invalid {
  outline: none;
  border-bottom: 1px solid $remove_item_color;
}

input[type='text'].cwp:invalid:focus,
input[type='password'].cwp:invalid:focus,
input[type='secret'].cwp:invalid:focus,
input[type='email'].cwp:invalid:focus,
input.cwpEmail:invalid:focus,
input[type='tel'].cwp:invalid:focus,
input[type='date'].cwp:invalid:focus,
select.cwp:invalid:focus {
  outline: none;
  border-bottom: 1px solid $remove_item_color;
}

input[type='date'].cwp {
  background-image: url('../../assets/icons/calendar.svg');
  cursor: pointer;
}

select.cwp {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../assets/icons/dropdown.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 6px);
  padding-right: 30px;
}

.inputIconSearch {
  background-image: url(../../assets/icons/magnifier.svg);
  background-position-x: right 5px;
  background-repeat: no-repeat;
}

button.cwp,
input[type='button'].cwp,
input[type='submit'].cwp {
  background-color: $button_primary_face;
  border-style: none;
  color: $button_primary_text;
  font-size: 1rem;
  line-height: 1rem;
  border-radius: 22px;
  outline: none;
  padding: 15px 30px;
  width: auto;
  font-weight: bold;
}

.cwpFocusVisible:focus,
*:focus {
  outline: 3px solid $focus_outline_color;
}

button.cwp:focus,
button.cwpSecondary:focus,
input[type='button']:focus,
input[type='submit']:focus,
select.cwp:focus,
span.carnetLink:focus,
a.carnetLink:focus,
span.carnetLinkLight:focus,
a.carnetLinkLight:focus,
button.carnetLinkLight:focus,
button.carnetLink:focus,
.cwpCheckbox:focus,
.cwpRadio:focus,
.cwpBack:focus,
.arrowRight:focus,
.cwpArrowLink:focus,
.cwpMenu:focus {
  outline: 3px solid $focus_outline_color;
}

button.cwp,
button.cwpSecondary {
  font-family: 'VWHeadLight';
  font-size: 1em;
}

button.cwp:hover {
  color: $button_primary_text;
}

button.cwp:disabled,
input[type='button'].cwp:disabled,
input[type='submit'].cwp:disabled {
  background-color: $button_disabled_face;
  color: $button_disabled_text;
}

button.cwpSecondary,
input[type='button'].cwpSecondary,
input[type='submit'].cwpSecondary {
  background-color: $button_secondary_face;
  color: $button_secondary_text;
  border: 2px solid $button_secondary_border;
  padding: 13px 28px;
  border-radius: 22px;
  font-size: 1rem;
  line-height: 1rem;
  width: auto;
}

button.cwpSecondary:hover,
input.cwpSecondary:hover {
  color: $button_secondary_text;
}

input[type='checkbox'].cwp,
input[type='radio'].cwp {
  height: 30px;
  width: 20px;
  float: left;
  margin-right: 10px;
}

button.cwpMenu {
  background-image: url(../../assets/icons/menu_icon.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

button.cwpMenuDisabled {
  background-image: url(../../assets/icons/menu_icon_disabled.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
}

button.cwpMenu:hover {
  border: 1px solid $link_text_light_color;
  border-radius: 1px;
}

span.carnetLink,
a.carnetLink {
  color: $link_text_color;
  cursor: pointer;
  text-decoration: none !important;
  padding: auto 0.5rem auto 0.5rem;
}

span.carnetLinkLight,
a.carnetLinkLight {
  color: $link_text_light_color;
  cursor: pointer;
  text-decoration: none !important;
  padding: auto 0.5rem auto 0.5rem;
}

button.carnetLink,
button.carnetLink:hover,
button.carnetLink:focus {
  color: $carnet_root_color;
  text-decoration: none !important;
  padding: auto 0.5rem auto 0.5rem;
  font-weight: bold;
  font-size: 1rem;
}

button.carnetLinkLight,
button.carnetLinkLight:hover {
  color: $link_text_light_color;
  text-decoration: none !important;
  padding: auto 0.5rem auto 0.5rem;
  font-weight: normal;
  font-size: 1rem;
}

span.cwpExit {
  background-image: url(../../assets/icons/close-circle.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  float: right;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

input[type='checkbox'].cwpCheckbox {
  appearance: none;
  width: 20px;
  height: 20px;
}

.cwpCheckbox {
  background-image: url(../../assets/icons/checkbox-off.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 20px;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.cwpCheckboxDisabled {
  background-image: url(../../assets/icons/checkbox-off-grayed.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 30px;
  width: 24px;
  height: 24px;
}

.cwpCheckboxOn {
  background-image: url(../../assets/icons/checkbox-on.svg);
}

.cwpCheckboxOnDisabled {
  background-image: url(../../assets/icons/checkbox-on-grayed.svg);
}

.cwpRadio {
  background-image: url(../../assets/icons/radio-button-off.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 30px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &.disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.cwpRadioOn {
  background-image: url(../../assets/icons/radio-button-on.svg);
}

.cwpBack {
  background-image: url(../../assets/icons/arrow-left-tall.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  padding-left: 30px;
  min-width: 1.5rem;
  min-height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  cursor: pointer;
}

.cwpCol {
  max-width: 320px;
  width: 100%;
  display: inline-block;
  margin: auto;
  text-align: left;
}

label.cwp {
  font-size: 12px;
  margin-bottom: 0px;
}

.cwpToggleOff {
  background-image: url(../../assets/icons/Toggle-Off.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-left: 30px;
  width: 60px;
  height: 24px;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
}

.cwpToggleOn {
  background-image: url(../../assets/icons/Toggle-On.svg);
}

span.cwpInputEye {
  position: absolute;
  left: calc(50% + 125px);
  background-image: url(../../assets/icons/crossed-eye-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.cwpCol span.cwpInputEye {
  left: calc(50% + 115px);
}

span.cwpInputEyeOpen {
  background-image: url(../../assets/icons/open-eye-icon.svg);
}

.cwpSpinner {
  background-image: url(../../assets/images/spinner.gif);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-left: 30px;
  width: 50px;
  height: 7px;
  cursor: pointer;
}

.cwpEditLink {
  color: $link_text_color;
  cursor: pointer;
  text-decoration: none !important;
  background-image: url(../../assets/icons/edit.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 12px 12px;
  min-height: 12px;
  min-width: 24px;
  display: inline-block;
  padding-left: 20px;
}

.cwpArrowLink {
  color: $link_text_color;
  cursor: pointer;
  text-decoration: none !important;
  background-image: url(../../assets/icons/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 12px 12px;
  min-height: 12px;
  min-width: 24px;
  display: inline-block;
  padding-left: 20px;
}

.cwpLabel {
  color: $text_label_dark_gray_color;
}

.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 0.2;
}
.opacity-2 {
  opacity: 0.4;
}
.opacity-3 {
  opacity: 0.6;
}
.opacity-4 {
  opacity: 0.8;
}
.opacity-5 {
  opacity: 1;
}

.cwpPointerEventsNone {
  pointer-events: none;
}

.cwpCursorPointer {
  cursor: pointer;
}

input[type='image'].cwp {
  height: 1.5rem;
  min-width: 1.5rem;
}

table.cwp th {
  font-size: 1rem;
}

span.cwpCheckbox:focus {
  border: 1px solid $link_text_light_color;
  border-radius: 1px;
}

@media only screen and (max-width: 375px) {
  .cwp-header #btnCarnetHome {
    width: 143px;
    cursor: pointer;
    float: left;
    margin-left: 1rem !important;
  }
}

.titleDescription {
  color: $text_label_dark_color;
}

sup.cwp-disclaimer {
  background-color: $disclaimer_background_color;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  font-weight: lighter;
  vertical-align: super;
}

button.cwp-disclaimer {
  background-color: $disclaimer_background_color;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  font-weight: lighter;
  border: none;
  vertical-align: super;
}

.cwp-border-lagacy {
  border-color: $liner_color;
  border-width: 1px;
  border-style: solid;
}

.cwp-border {
  border: 1px solid $button_primary_face;
}

.backdrop {
  position: static;
  display: flex;
  min-width: 100vw;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-color: $background_color;
}

.spinner-color {
  color: $carnet_root_color;
}

.cwpFontSmall {
  font-size: 0.75rem;
}

.articleBox {
  background-color: $article_background_color;
  border: 1px solid $hint_grey;
}

.scale125 {
  transform: scale(1.25);
}

.scale150 {
  transform: scale(1.5);
}

.scale175 {
  transform: scale(1.75);
}

.scale200 {
  transform: scale(2);
}

// override for ReactShowMoreText inner span
.checkboxLabelcorrectedWidth {
  span {
    span {
      max-width: 100% !important;
    }
  }
}
