@import '../reusable_cmp_lib/colorVariables.scss';
.languageToggle {
  padding-top: 1px;
  display: inline-block;
  color: $carnet_root_color;

  &.inverted {
    color: #fff;
  }

  ul {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    float: right;
    list-style: none;
    li {
      span,
      a {
        color: inherit !important;
      }
      display: inline-block;
      &:not(:last-child) {
        margin-right: 5px;
        padding-right: 5px;
        border-right: 1px solid;
        line-height: 0.9em;
      }
      .selected {
        text-decoration: underline;
      }
    }
    margin: 0 5px 0 5px;
  }
}
