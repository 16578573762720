$modal_background_color: #5b08a4;
$modal_text_color: white;
$newtheme_marine_dark: #003c65;
$newtheme_accent: #00b0f0;
$newtheme_hint: #a9a9a9;
$hint_grey: #b9b9b9;
$form_field_underline: #707070;
$newtheme_suggestion: #c5c5c5;
$data_subdued: #6a767d;
$june_designchange_newhdrsnlabels: #001e50;

$carnet_root_color: #001e50;
$hyperfaint_underline_color: #d2dadf;
$popups_pearl_color: #c2cacf;
$faintest_suggestion_color: #96a3a8;

$inputbox_border: #dfe4e8;
$inputbox_border_focused: #001e50;

$button_primary_face: #001e50;
$button_primary_text: #fff;
$button_primary_border: #001e50;

$button_secondary_face: #fff;
$button_secondary_text: #001e50;
$button_secondary_border: #001e50;

$button_disabled_face: #dfe4e8;
$button_disabled_text: #6a767d;
$button_disabled_border: #6a767d;

//Color Added
$primary_color: #001e50;
$text_primary_color: #000;
$text_label_color: #7f7f7f;
$text_label_dark_color: #6a767d;
$background_color: #fff;
$link_text_color: #00b0f0;

$link_text_light_color: #007acc;
$background_light_color: #fff;
$background_color_classic: #a1d0ff;

$box_border_sides_color: #dfe4e8;
$liner_color: #00b0f0;
$remove_item_color: #e4002c;
$disclaimer_background_color: #001e50;

$text_success_color: #02883a;
$article_background_color: #f9f9f9;

$text_input_border_color: #afb5b6;
$text_input_border_bottom_color: #eee;
$text_label_dark_gray_color: #6a6a6a;

$focus_outline_color: #00b1eb;

$overlay_color: #818181;
$disclaimer_background_color: #f2f4f6;
$cart_button_color: #00c971;
$bg_warn: #ffd100;
