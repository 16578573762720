@import '../colorVariables.scss';
@import '../../carnet/CarnetHome.scss';

.cwpThemedModal {
  padding: 10px;
  color: #fff;
  border-radius: 0;

  .modal-header {
    color: #fff;
    font-size: 22px;
  }

  .modal-content {
    background-color: $modal_background_color;
    border-radius: 0;
    margin-top: 100px;
  }

  .modal-footer {
    justify-content: center;
    border-top: 0px;
  }

  .themedToastBtn {
    border: none;
    font-size: 14px;
    font-weight: bolder;
  }

  .secondaryModalButton.cwpSecondary.themedToastBtn.btn-secondary.btn {
    background-color: $modal_background_color !important;
    color: $modal_text_color !important;
    border: 1px solid $modal_text_color !important;
  }

  .messageLink {
    text-decoration: underline;
    cursor: pointer;
  }
}
