@import '../../reusable_cmp_lib/colorVariables.scss';

.battery-header {
  background-color: $carnet_root_color;

  hr {
    border: 1px solid $background_color;
  }

  .car-net-logo {
    float: left;
    margin: 21px;
  }

  .vw-logo {
    background-color: $carnet_root_color;
    display: inline-block;
    background-image: url(../../../assets/images/logo.png);
    background-size: 55px;
    background-repeat: no-repeat;
    width: 60px;
    height: 55px;
    position: absolute;
    right: 33%;
    top: 35px;
  }

  .model {
    background-color: $carnet_root_color;
    height: 62px;
  }

  .lang-wrap-right {
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }

  .battery-locale {
    text-align: right;
    margin-right: 20px;
  }

  .hr-line {
    margin-top: -26px;
  }

  .hr-line.us {
    margin-top: 5px;
  }
}
