.cardCurrentPlan {
  box-sizing: border-box;
  max-width: 580px;
  min-height: 282px;
  padding: 2rem;
  border: 1px solid $inputbox_border_focused;
  border-left-width: 4px;
}

.iconPlan {
  width: 30px;
  height: 30px;
}

.planStatus {
  display: flex;
  align-items: center;
  justify-self: flex-end;

  color: $text_label_dark_color;
  font-size: 0.75rem;
  font-weight: bold;

  padding-left: 1.5rem;
  margin-left: 0.5rem;

  background-size: 1rem;
  background-position: left center;
  background-repeat: no-repeat;
}

.bgIconActive {
  background-image: url('../../../../assets/buyPlans/active.svg');
}

.bgIconPending {
  background-image: url('../../../../assets/buyPlans/pending.svg');
}

.bgIconExpired {
  background-image: url('../../../../assets/buyPlans/expired.png');
}

button.bgArrowRight {
  background-image: url('../../../../assets/buyPlans/right.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 20px;
}

.dataPlanCancelInfo {
  color: $carnet_root_color;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 0.5rem;
}

.cardAdditionalPlan {
  box-sizing: border-box;
  max-width: 384px;
  border: 1px solid $inputbox_border;
  border-top: 4px solid $inputbox_border_focused;
}

.feature {
  background-image: url('../../../../assets/buyPlans/active.svg');
  background-repeat: no-repeat;
  background-position: left 0.33rem;
  background-size: 1rem;
  padding-left: 2rem;
  font-size: 1rem;
}

.hrAdditionalPlan {
  border: 1px solid $inputbox_border;
  margin: 0px;
  width: 100%;
}
