.tosUpdateHtmlContent {
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  background-color: #f9f9f9;
  padding: 35px;
}

.tosscroll {
  width: 55%;
  overflow-y: scroll;
  height: 200px;
  margin: 0 auto;
  margin-top: 0px;
  text-align: justify;
  margin-top: 60px;
  background-color: #f9f9f9;
  padding: 36px;
}
.mkt-consent {
  text-align: left;
  margin-top: 45px;
}

.acceptlbl {
  margin-bottom: 2px;
  margin-left: 10px;
}

.d-flex-vw {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 18px;
}

.tos-consent-mkt {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.open-link {
  font-size: 20px;
  color: #6a767d;
  margin-top: 23px;
  margin-bottom: 12px;
}

.tos-consent {
  text-align: left;
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 20px;
}
.pre-tos {
  white-space: normal;
}
