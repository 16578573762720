.App {
  text-align: center;
  display: flow-root;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.page-container {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.srSkipToMainContentLink {
  position: absolute;
  left: -10000px;
  top: 140px;
  width: auto;
  height: auto;
  overflow: hidden;
  z-index: 900;
  padding: 5px;
  &:focus {
    left: 27px;
    background: #fff;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
}

.content-wrap {
  flex: 1;
  -ms-flex: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.preLoadingBlock {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6a6a6a;
  opacity: 1;
  /* Firefox */
}
