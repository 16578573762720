$primary: #00b1eb;
$secondary: #2274ac;
$info: #00b1eb;

.btn {
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: large;
}
