@import '../../reusable_cmp_lib/colorVariables.scss';

.vehicleHistoryNoResultsFound {
  font-family: VWText;
  font-size: 16px;
  color: $text_label_dark_color;
  margin-top: 23px;
}

.vehicleHistoryHeader {
  font-family: VWText;
  font-size: 20px !important;
  font-weight: bold;
  color: $primary_color;
  border-top: none !important;
  line-height: 1.2;
}
.historyDelete:focus {
  outline: 3px solid $focus_outline_color;
}

.historyDeleteAction {
  color: $remove_item_color;
  text-align: center;
}

.inlineLoaderWrapper {
  padding: 8px 0 0 0;
  margin: 1px;
}
