@import '../../reusable_cmp_lib/colorVariables.scss';
.navbar-toggler-icon {
  background-image: url(../../../assets/landingPage/res/menu.png) !important;
  background-size: 24px !important;
}
.row {
  margin-right: 23px !important;
  margin-left: 10px !important;
}
.bg-danger {
  background-color: $primary_color !important;
}

.navbar.navbar-expand-xl.navbar-dark.bg-danger,
.navbar.navbar-expand-lg.navbar-dark.bg-danger {
  background-color: $primary_color !important;
}

a:hover {
  text-decoration: none !important;
  color: $modal_text_color;
}
.navbar-dark .navbar-nav .nav-link {
  padding-right: 44px !important;
}

@media only screen and (min-width: 1024px) {
  .mr-auto.navbar-nav {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 960px) {
  .navbar.navbar-expand-xl.navbar-dark.bg-danger,
  .navbar.navbar-expand-lg.navbar-dark.bg-danger {
    background-color: $primary_color !important;
  }

  a.logout {
    margin-top: 5px;
    font-size: 1em !important;
    font-weight: bold;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: $modal_text_color;
    font-size: 18px;
  }
  .navbar-dark .navbar-nav .nav-link {
    padding-right: 44px !important;
  }
  .navbar-brand {
    display: none !important;
  }

  .navbar-collapse {
    text-align: center;
    z-index: 1;
    background-color: $button_primary_text;
    height: 126vh;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: $button_secondary_text !important;
  }

  .navbar {
    position: relative;
    padding: 0.5rem 0rem !important;
    height: 68px;
  }
  .mr-auto.navbar-nav {
    text-align: left;
    margin: 34px 18px 10px !important;
    padding: 18px 39px 8px 11px;
    line-height: 2.25;
  }

  .navbar-nav {
    text-align: left;
    margin: -18px 0px 0px 28px !important;
    line-height: 2.25;
  }
  .logInOut.inverted span {
    color: $button_secondary_text !important;
    font-size: 17px;
    font-weight: lighter !important;
  }

  .languageToggle.inverted {
    color: $button_secondary_text !important;
    font-weight: bold;
    border-bottom: 1px solid $box_border_sides_color;
    width: 100vw;
    left: -20%;
    transform: translateX(13%);
    position: absolute;
    top: 50px;
  }
  .cwp-header-post-login #vwlogo-post-login {
    background-size: 33px;
    width: 43px;
    height: 61px;
    position: absolute;
    right: 41%;
    top: -34px;
  }
}

@media only screen and (min-width: 965px) {
  .navbar.navbar-expand-xl.navbar-dark.bg-danger {
    background-color: $button_primary_text !important;
    height: 70px;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: $modal_text_color !important;
  }
  .nav-link.active {
    color: $liner_color !important;
  }

  .mr-auto.navbar-nav {
    font-family: 'VWTextBold';
    font-size: 17px;
  }
  .navbar-nav {
    font-size: 17px;
    z-index: 1;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: 50% / 100% 100% no-repeat;
  }
}
