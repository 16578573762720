@import '../../../reusable_cmp_lib/colorVariables.scss';

.buyPlans {
  @import './buyPlansHome.scss';
  @import './checkout.scss';

  .container {
    box-sizing: border-box;
    max-width: 1280px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  a.linkBack {
    background-image: url(../../../../assets/icons/arrow-left-tall.svg);
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
    color: $carnet_root_color;
  }

  #buyPlansDisclaimer {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    opacity: 0.95;
  }

  article.cartButton {
    position: fixed;
    top: 20%;
    right: 0px;

    input#btnCart {
      border-top-left-radius: 40%;
      border-bottom-left-radius: 40%;
    }

    label {
      cursor: pointer;
      background-color: $modal_background_color;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      font-size: 12px;
      color: $modal_text_color;
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 15%;
      left: 15%;
    }
  }

  section.border {
    border: 1px solid $inputbox_border_focused !important;
  }

  .cartItem {
    box-sizing: border-box;
    border: 1px solid $inputbox_border_focused;
    border-left-width: 4px;
    width: 100%;
    text-align: left;
  }

  button.delete {
    color: $remove_item_color;
    font-weight: 900;
    font-size: 0.75rem;
  }

  button.inCart {
    background-color: $cart_button_color;
    color: $button_primary_text;
    font-weight: bold;
  }

  .infoIcon {
    position: absolute;
    left: calc(300px - 1.25rem);
  }

  .providerIconTMO {
    padding-left: 40px;
  }

  .bgIconSuccess {
    background-image: url(../../../../assets/buyPlans/active.svg);
    background-size: 1.2rem;
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 2rem;
  }

  .text-primary {
    color: $carnet_root_color !important;
  }

  article.myQLinkStatus {
    background-color: $inputbox_border;
    padding: 1rem;
    border-radius: 0.5rem;

    button.myQLinkButton {
      background-color: transparent !important;
      color: $link_text_color;
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
}

input[type='image'].yellowShoppingCart {
  background-color: $bg_warn;
  border: none;
  border-radius: 50%;
  padding: 1rem;
  min-height: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
}
