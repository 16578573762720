@import '../../reusable_cmp_lib/colorVariables.scss';

div.audioSubDiv {
  table.tb-margin {
    tr.subscription-data-row {
      .audio-text-td {
        margin-top: 26px;
      }
    }
  }
}
