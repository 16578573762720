@import '../../reusable_cmp_lib/colorVariables.scss';
.carnet-modal-design2 {
  min-width: 200px;
  max-width: 700px;
  width: auto;

  .row {
    flex-wrap: wrap;
  }

  .action-link {
    cursor: pointer;
  }

  .dealer-detail-hours-label {
    font-size: 14px;
    font-weight: bold;
    font-family: 'VWText';
    color: $primary_color;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .dlr-dtl-hours-table {
    color: $text_primary_color;
    padding: 0;
    margin: 0;

    .dlr-dtl-hours-row {
      font-size: 14px;
      font-weight: normal;
      font-family: 'VWText';
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin: 0;
    }

    .dlr-hours-col {
      font-size: 14px;
      width: 100%;
      padding: 0;
      margin: 0;
      span {
        display: inline-block;
        min-width: 90px;
      }
    }
  }

  #inmodal-select-radio {
    text-align: right;
  }

  .dlr-result-selection-option {
    margin-top: 15px;
  }

  #dealer-links-col {
    text-align: right;

    .websiteLink {
      line-height: 0.7;
      font-size: 0.9em;
      margin-block-end: 6px;
      margin-top: 10px;

      margin-top: 18px;
      font-size: 0.8em;

      a {
        color: $newtheme_marine_dark;
        margin-right: 15px;
        &:hover {
          text-decoration: underline !important;
        }
      }

      .service {
        background-image: url('../../../assets/icons/service-bell.svg');
        background-repeat: no-repeat;
        padding-left: 18px;
      }
    }
  }

  .records-table-rows {
    border: none !important;
    .dealer-address-text {
      font-family: 'VWText';
      font-size: 14px;
      color: $text_primary_color;
      font-weight: normal;
    }
  }

  .dealer-detail-modal-header {
    font-family: 'VWHead';
    font-size: 20px;
    object-fit: contain;
    font-weight: bold;
    line-height: 1.2;
    color: $text_primary_color;

    .modal-title {
      width: 100% !important;

      #dealer-detail-modal-title {
        h2 {
          margin: 0;
          padding: 0;
          margin-top: 10px;
        }
      }

      .modalTitleRow {
        display: flex;
        justify-content: space-between;
        margin-left: 3px !important;
        margin-right: 3px !important;

        > div {
          padding: 0;
          margin: 0;
          display: inline;
          flex: 1;
        }

        #dealer-detail-modal-x {
          vertical-align: top;
          margin: 0;
          padding: 0;
          width: 35px;
          height: 35px;
          max-width: 35px;
          padding: 5px;
          text-align: right;
          #dlr-detail-close-btn-icon {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }
      }
    }
  }

  #macm-body-instructions {
    object-fit: contain;
    font-family: 'VWText';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $carnet_root_color;
  }

  .records-table-rows {
    border-bottom: 1px solid $hyperfaint_underline_color;
    padding: 8px 0px;
  }

  .macm-record-column-lbl {
    font-family: 'VWHead';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $popups_pearl_color;
  }

  .add-record-suggestion {
    font-family: 'VWHead';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $faintest_suggestion_color;
  }

  .existing-record-text {
    font-family: 'VWHead';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    padding: 10px 0px;
    letter-spacing: normal;
    color: $carnet_root_color;
  }

  .add-contact-field-link {
    font-family: 'VWText';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $newtheme_accent;
  }

  .erroneous {
    color: $remove_item_color;
  }
}

div.dealerDetailCloseBtn:focus,
a.dealerSite:focus {
  outline: 3px solid $focus_outline_color;
}

@media only screen and (max-width: 768px) {
  .carnet-modal-design2 {
    #inmodal-select-radio {
      text-align: left;
    }
    #dealer-links-col {
      text-align: left;
    }
  }
}

@media only screen and (max-width: 767px) and (min-width: 543px) {
  .dlr-dtl-hours-table {
    width: 400px !important;
  }
}
