@import '../../reusable_cmp_lib/colorVariables.scss';

.tosEnrollmentSummaryCAWrapper {
  display: block;
  width: 100%;

  .tosEnrollmentSummaryCA {
    min-height: 100px;
    width: 100%;
    padding: 0 20px;
    display: -ms-flex;
    display: flex;

    &.paddingBottom {
      padding-bottom: 40px;
    }

    .col {
      text-align: left;
      flex: 1;
      -ms-flex: 1;
      width: 15%;
      min-width: 15%;
      display: block;

      .cwpBack {
        font-size: 0.8em;
      }

      &.center {
        flex: 3;
        -ms-flex: 3;
        width: 70%;
        min-width: 70%;

        h1 {
          text-align: center;
          margin: 0;
          padding: 0 0 40px 0;
          width: 100%;
        }

        .cc_txt {
          text-align: center;
          margin-bottom: 40px;
        }

        .subhead_txt {
          display: inline-block;
          width: 100%;
          font-size: 0.8em;
          color: #000;
          margin-bottom: 20px;
        }
      }

      &.right {
        text-align: right;

        .helpText {
          display: inline-block;
          width: 100%;
          font-size: 0.8em;
          color: #000;
        }
      }

      &.divided {
        display: -ms-flexbox;
        display: flex;

        .oneThirdLeft {
          -ms-flex: 1;
          flex: 1;
        }

        .twoThirdsRight {
          margin-left: 20px;
          -ms-flex: 3;
          flex: 3;

          .serviceInfoBox {
            width: 100%;

            border: 0.5px solid $text_input_border_color;
            border-top: 4px solid $primary_color;

            .top {
              width: 100%;
              padding: 22px 20px 10px 20px;
              padding-left: 20px !important;

              img {
                padding-bottom: 10px;
              }

              .inline {
                display: inline-block;
                margin-left: 20px;
              }
            }

            div {
              width: 92%;
              margin: auto;
              padding: 10px;
              min-height: 50px;
              font-size: 0.9em;
            }

            .sectional {
              border-bottom: 0.5px solid $text_input_border_color;
              color: #000;
              padding-left: 0;
              padding-right: 0;

              &.dataTrialSummary {
                border-bottom: none;
              }

              &.triplets {
                display: -ms-flexbox;
                display: flex;
                div {
                  -ms-flex: 1;
                  flex: 1;
                  padding: 0;
                }
              }

              .cwpCheckbox {
                opacity: 0.5;
                cursor: default;
              }

              .disabledChecks {
                padding: 0;
                margin: 0;
              }

              .checkedDisclaimerText {
                color: $button_disabled_text;
              }

              &.last {
                border-bottom: none;
              }

              .monthlyPricePrecursor {
                color: $text_primary_color;
              }

              .monthlyPrice {
                font-size: 3.5em;
                display: inline;
                color: $link_text_color;

                sup {
                  font-size: 0.6em;
                }
              }

              .pricePerLabel {
                margin-left: -26px;
              }

              .description {
                font-size: 0.9em;
                color: $text_primary_color;
              }
            }
          }

          .pricingBreakdown {
            text-align: right;
            margin-top: 20px;
            margin-bottom: 20px;

            .priceBreakdownLabel {
              font-size: 1.2em;
              color: #707070;

              &.last {
                font-size: 1.4em;
                color: $primary_color;
              }
            }

            .priceBreakdownValue {
              color: $text_primary_color;
            }
          }
        }
      }
    }

    .paddingRight45Px {
      padding-right: 45px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .tosEnrollmentSummaryCAWrapper {
    display: block;
    width: 100%;

    .tosEnrollmentSummaryCA {
      min-height: 100px;
      width: 100%;
      padding: 0 20px;
      display: block;

      &.paddingBottom {
        padding-bottom: 40px;
      }

      .col {
        text-align: left;
        flex: 1;
        -ms-flex: 1;
        width: 15%;
        min-width: 15%;
        display: block;

        .cwpBack {
          font-size: 0.8em;
          width: 210px;
          display: inline-block;
        }

        &.center {
          flex: 3;
          -ms-flex: 3;
          width: 100%;
          min-width: 100%;
          text-align: justify;

          h1 {
            text-align: center;
            margin: 0;
            padding: 18px 0 20px;
            width: 100%;
          }

          h5 {
            text-align: center;
            margin: 0;
            padding: 18px 0 10px;
            width: 100%;
          }

          .cc_txt {
            text-align: center;
            margin-bottom: 40px;
          }

          .subhead_txt {
            display: inline-block;
            width: 100%;
            font-size: 0.8em;
            color: #000;
            margin-bottom: 20px;
          }
        }

        &.right {
          text-align: center;
          width: 100%;

          .helpText {
            display: inline-block;
            width: 100%;
            font-size: 0.8em;
            color: #000;
          }
        }

        &.divided {
          display: -ms-flexbox;
          display: block;

          .oneThirdLeft {
            text-align: center;
          }

          .twoThirdsRight {
            margin-left: 0px;
            -ms-flex: 3;
            flex: 3;

            .serviceInfoBox {
              width: 100%;

              border: 0.5px solid $text_input_border_color;
              border-top: 4px solid $primary_color;

              .top {
                width: 100%;
                padding: 22px 20px 10px 20px;
                padding-left: 20px !important;

                img {
                  padding-bottom: 10px;
                }

                .inline {
                  display: inline-block;
                  margin-left: 20px;
                }
              }

              div {
                width: 92%;
                margin: auto;
                padding: 10px;
                min-height: 50px;
                font-size: 0.9em;
              }

              .sectional {
                border-bottom: 0.5px solid $text_input_border_color;
                color: #000;
                padding-left: 0;
                padding-right: 0;

                &.triplets {
                  display: -ms-flexbox;
                  display: flex;

                  div {
                    -ms-flex: 1;
                    flex: 1;
                    padding: 0;
                  }
                }

                .cwpCheckbox {
                  opacity: 0.5;
                  cursor: default;
                }

                .disabledChecks {
                  padding: 0;
                  margin: 0;
                }

                .checkedDisclaimerText {
                  color: $button_disabled_text;
                }

                &.last {
                  border-bottom: none;
                }

                .monthlyPricePrecursor {
                  color: $text_primary_color;
                }

                .monthlyPrice {
                  font-size: 2.5em;
                  display: inline;
                  color: $link_text_color;

                  sup {
                    font-size: 0.6em;
                  }
                }

                .pricePerLabel {
                  margin-left: -12px;
                }

                .description {
                  font-size: 0.9em;
                  color: $text_primary_color;
                }
              }
            }

            .pricingBreakdown {
              text-align: right;
              margin-top: 20px;
              margin-bottom: 20px;

              .priceBreakdownLabel {
                font-size: 1.2em;
                color: #707070;

                &.last {
                  font-size: 1.4em;
                  color: $primary_color;
                }
              }

              .priceBreakdownValue {
                color: $text_primary_color;
              }
            }
          }
        }
      }

      .paddingRight45Px {
        padding-right: 45px;
      }
    }
  }
}
