@import '../../reusable_cmp_lib/colorVariables.scss';
.sessionWarning {
  position: fixed;
  top: 30px;
  right: 10px;
  margin: auto;
  z-index: 10;
  background: #ffd100;
  width: 300px;
  padding: 20px;

  span {
    color: $text_label_dark_color !important;
    font-family: 'VWText';
    font-size: 0.95em !important;

    &.exitRight {
      float: right;
      background-image: url(../../../assets/icons/close-circle-grey.svg);
    }

    &.extendLink {
      display: inline-block;
      margin-top: 10px;
      color: $primary_color !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.expired {
    background: #e4002c;
    span {
      color: #fff !important;

      &.exitRight {
        background-image: url(../../../assets/icons/close-circle-white.svg);
      }
    }
  }
}
