@import '../../reusable_cmp_lib/colorVariables.scss';
@import '../CarnetHome.scss';

.row-soft-underline {
  border-bottom: 2px groove $hint_grey;
}

.emergency-contact-subtitle-div {
  width: 340px;
  margin: 6px 205px 0px 0px;
  line-height: 1.71;

  .emergency-contact-subtitle-text {
    font-family: 'VWText';
    font-size: 12px;
    font-weight: normal;
    color: $text_primary_color;
  }
}

#subscription-call-msg {
  font-weight: bold;
  font-size: 12px;
  font-family: 'VWText';
}

#subscription-call-number {
  font-family: 'VWText';
  font-size: 14px;
  font-weight: bold;
  color: $newtheme_accent;
}

.left-aligner {
  text-align: left;
  padding-left: 20%;
  padding-right: auto;
}

.right-aligner {
  text-align: right;
  padding-left: auto;
  padding-right: 2px;
}

#standalone-parent-editor {
  text-align: center;
  padding: auto;
  vertical-align: center;
}

#phone-writein-switch {
  padding: 4px 6px;
  background-color: $newtheme_marine_dark;
  color: white;
  font-size: 10px;
  font-weight: bold;
  font-family: 'VWText';
  cursor: pointer;
}

.design-deviation-footer {
  display: block;
  align-items: center;
  border: none !important;
  padding-left: 10%;

  .btn {
    display: block;
    align-items: normal;
    width: 26em;
  }
}

#confirm-uncheck-ubi-service,
#cancel-uncheck-ubi-service,
#confirm-unenroll-ubi-service,
#cancel-unenroll-ubi-service {
  display: block;
  width: 70%;
  margin-bottom: 5px;
  margin-right: 16%;
}

.ubi-service-chkbox-lbl {
  font-family: 'VWText';
  font-size: 16px;
  font-weight: 400;
  color: rgb(0, 30, 80);
}

#exit-editing-link {
  color: $june_designchange_newhdrsnlabels;
  cursor: pointer;
  padding: 0;
  margin-left: 60px;
  text-align: left;
  vertical-align: middle;
  width: 260px;
  float: left;

  .arrow {
    margin: 0 20px 0 0;
    padding: 0;
    font-size: 1.1em;
  }

  .text {
    margin: 0;
    padding: 0;
    font-size: 0.8em;
  }
}

.raw-select-label-text {
  vertical-align: top;
  font-family: 'VWText';
  font-size: 12px;
  line-height: 1.33;
  padding: 0;
  margin: 0;
  color: $june_designchange_newhdrsnlabels;
}

#warning-popup {
  position: absolute;
  border-radius: 8px;
  top: 125px;
  left: 3px;
  z-index: 9;
  background-color: $modal_background_color;
  width: 160px;
  text-align: left;
  padding: 6px 4px;
  #warning-popup-text {
    color: $modal_text_color;
    text-align: left;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.1;
  }
}

#disabled-link-wrapper {
  padding: 13px 8px;
  min-height: 150px;
  width: 100%;
}

.am-tooltip {
  position: absolute;
  top: -10px;
  left: 3px;
  z-index: 9;
  background-color: $modal_background_color;
  width: 200px;
  text-align: left;
  padding: 6px 4px;
  .am-tooltip-text {
    color: $modal_text_color;
    text-align: left;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.1;
  }
}

.tooltip-newstyle {
  position: absolute;
  left: -20px;
  top: -40px;
  max-width: 18em;
  padding: 5px 8px;
  background: $modal_background_color;
  border-radius: 0px;
  color: white;
  z-index: 9;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: $modal_background_color;
    border-bottom: 0;
    border-left: 0;
    margin-left: -10px;
    margin-bottom: -20px;
  }
  .tooltip-text {
    overflow-wrap: normal;
    font-family: 'VWText';
    font-size: 8pt;
    font-weight: bold;
  }
}

.address-suggestion {
  font-size: 12pt;
  cursor: pointer;
  text-align: left;

  .address-suggestion-lineitem {
    font-weight: normal;
    color: white;
    text-align: left;
    padding: 4px 6px;
  }

  .address-suggestion-lineitem-select {
    color: $newtheme_hint;
    font-size: 10pt;
    font-weight: bold;
    padding: 4px 6px 2px 6px;
  }

  border: 1px solid rgba(255, 255, 255, 0);
}

.address-suggestion:hover {
  border: 1px solid rgba(255, 255, 255, 1);
}

#edit-address-form {
  #valid-address-indicator {
    background-color: green;
    color: white;
    font-weight: bold;
    font-size: 14px;
    font-family: 'VWText';
  }
}

#new-userid-field {
  text-align: left;
  #userid-field {
    text-align: left;
  }
}

.userid-entry-input {
  width: 24em;
  max-width: 24em;
}

.problematic-div {
  padding-right: auto;
  text-align: left;
  padding-left: 0px;
}

.indicator-div {
  max-width: 400px;
  width: 400px;
}

.vscenroll-icons {
  width: 40px;
  height: 40px;
  margin-right: 4px;
}

#unenroll-vsc-page {
  #phone-type-indicator {
    margin-top: 2px;
  }

  #phone-dropdown-aligner {
    padding-top: 15px;
  }

  #unenroll-vsc-page-description {
    padding-bottom: 6px;
    border-bottom: 1px solid #bababa;

    #unenroll-vsc-page-description-lbl {
      font-size: 18px;
      font-weight: 150;
      font-family: 'VWHeadLight';
      margin-bottom: 5px;
    }
  }

  #enroll-consent-statement {
    font-family: 'VWText';
    padding-top: 100px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.6;
    color: $text_primary_color;
  }

  #vsc-enroll-disclaimer {
    height: 40px;
    font-family: 'VWText';
    font-size: 9px;
    font-weight: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #6a767d;
  }

  .trow {
    .tcol {
      padding-top: 14px;
      float: left;
      width: 50%;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  #vsc-enroll-mketing-pitch-title {
    font-family: 'VWHeadLight';
    font-weight: 180;
    font-size: 32px;
    line-height: 1.13;
    width: 611px;
    #program-name {
      font-weight: bold;
    }
  }

  .vscenroll-splitcol-wrapper {
    width: 100%;
  }

  .vscenroll-splitcol-wrapper > div {
    float: left;
  }

  #vscenroll-col2 {
    width: 300px;
  }

  .vcs-selling-points {
    text-align: left;
    margin-bottom: 25px;

    .selling-point-label {
      font-family: 'VWHeadLight';
      font-size: 20px;
      margin-top: 4px;
      margin-bottom: 16px;
      height: 24px;
      font-weight: 840;
      line-height: 1.2;
      color: $text_primary_color;
    }

    .selling-point-prose {
      font-family: 'VWText';
      font-size: 14px;
      font-weight: normal;
      line-height: 1.71;
      margin-bottom: 28px;
    }
  }

  #vsc-unenroll-checkbox-aligner {
    margin: 14px auto;
    margin-left: 16px;
  }

  #vsc-unenroll-commit-btn {
    text-align: center;
    margin: 6px auto;
  }

  .vsc-unenroll-page-instrux {
    margin: 10px 4px;
  }

  #unenroll-vsc-page-instrux {
    #vsc-instrux-footnotes {
      font-family: 'VWText';
      padding-top: 20px;
      margin-top: 25px;
      font-weight: 150;
      padding: 0px 7px;
      font-size: 10px;
      ol {
        line-height: 1.75;
        padding: 5px 4px;
        li {
          line-height: 1.75;
          margin: 6px 0px;
        }
      }
    }
  }
}

#placeholder-page-title-aligner {
  text-align: center;
  span {
    font-family: 'VWHeadLight';
    font-size: 32px;
    font-weight: 300;
    line-height: 1.13;
    color: $text_primary_color;
  }
}

.hug-row-bottom {
  margin-top: 16px;
}

.delete-paired-device-alink-col {
  padding-left: 0px !important;
}

.recover-the-btn-border {
  border: 1px ridge $carnet_root_color !important;
  color: $carnet_root_color;
  white-space: nowrap !important;
  width: auto;
  max-height: 2.5em;
}

#pi-dob-field {
  background-image: none;
}

#invalid-phonenumber-indicator {
  background-color: $remove_item_color;
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 0.25rem;
  border-radius: 4px;
}
.invalid-indicator {
  margin: 0;
  padding: 0 0 0 5px;
  background-color: $remove_item_color;
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.invalid_input {
  border-color: $remove_item_color;
}

.add-phone-link-item {
  list-style-type: none;
  font-weight: bold;
  &.right {
    float: right;
  }

  .add-phone-link {
    color: $newtheme_marine_dark;
    font-size: 12pt;
    text-decoration: underline;
    cursor: pointer;
  }
}

.carnet-acctmgmt-leftnav {
  font-family: 'VWText';
  color: $text_primary_color;
}

.carnet-acctmgmt-nav {
  font-family: 'VWText';
  text-align: left;
  cursor: pointer;
  width: 94%;

  .acctmgmt-nav-header {
    border: none;
    margin-bottom: 8px;
    border-bottom: 1px ridge $newtheme_hint;
    border-radius: 0;
    font-family: 'VWHeadBold';
    font-size: 12pt;
    font-weight: lighter;
    width: 94%;
    .nav-header-label {
      color: $text_label_dark_color !important;
      font-size: 10pt !important;
      font-weight: lighter;
    }
  }
  ul {
    list-style-type: none !important;
    line-height: 4;
    text-align: left;
    font-size: 10pt;
    color: $newtheme_marine_dark;
    padding-left: 2px;
    padding-right: 45px;

    li {
      list-style-type: none !important;
      border: none;

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: auto;
        color: inherit;
        text-indent: 20px;
      }
    }
  }
}

.topOfEditContent {
  margin-top: 40px;
}

.acctmgmt-navitem {
  cursor: pointer;
  border: 1px ridge white !important;
}

.acctmgmt-navitem:hover {
  border: 1px solid $newtheme_marine_dark !important;
  border-left-width: 3px;
}

.acctmgmt-navitem.active {
  border: 1px solid $june_designchange_newhdrsnlabels !important;
  border-left-width: 3px;
}

.carnet-acctmgmt-parent {
  text-align: center;
  font-family: 'VWText';

  header {
    font-family: 'VWHeadLight';
    color: $newtheme_marine_dark;
  }

  pre {
    font-family: 'VWText';
  }

  input[type='text'].cwp,
  input[type='password'].cwp,
  input[type='secret'].cwp,
  input[type='email'].cwp,
  input[type='date'].cwp,
  input[type='phone'].cwp {
    margin-left: 0;
  }

  .cwp.pin {
    width: 50px;
  }

  .editTitle {
    display: inline-block;
    font-family: 'VWHeadLight';
    margin: 0;
    padding: 0;
  }

  .pageHeader {
    margin: 20px 0;
  }

  .hint-underline {
    border: none;
    border-bottom: 1px ridge $newtheme_hint;
  }

  .acctmgmt-dlr-search-results-ul {
    list-style-type: none;
    padding: 6px 4px 8px 4px;
    height: 70vh;
    overflow: hidden;
    overflow-y: scroll;

    li {
      margin: 0px 2px 20px 2px;
      padding: 0 0 20px 0;
      border-bottom: 1px solid $hint_grey;
      &:last-child {
        border-bottom: none;
      }
      .dlr-result {
        text-align: left;
        color: $text_primary_color;
        text-align: left;
        font-weight: normal;
        font-size: 10pt;

        .dlr-result-name-text {
          font-weight: bold;
          font-size: 14pt;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .dlr-result-name-text:hover {
          cursor: pointer;
          font-weight: bolder;
          border: 1px solid $focus_outline_color;
        }

        p {
          line-height: 0.7;
          font-size: 0.9em;
          margin-block-end: 6px;
          margin-top: 10px;

          &.websiteLink {
            margin-top: 18px;
            font-size: 0.8em;

            a {
              color: $newtheme_marine_dark;
              margin-right: 15px;
              &:hover {
                text-decoration: underline !important;
              }
            }

            .service {
              background-image: url('../../../assets/icons/service-bell.svg');
              background-repeat: no-repeat;
              padding-left: 18px;
              cursor: pointer;
            }
          }
        }

        .dlr-result-selection-option {
          margin-top: 15px;
        }
      }
    }
  }

  .modal-content {
    max-width: 400px;
  }
  .modal-header {
    max-width: 400px;
  }
  .modal-body {
    max-width: 390px;
  }

  .wide-dropdown {
    button.dropdown-toggle {
      width: 300px !important;
      background-color: #fff !important;
      height: 30px !important;
      text-align: right !important;
    }
  }

  #manage-services-permissions {
    padding: 1px 16px;
    min-height: 30px;
    .carnet-theme-btn {
      border-radius: 30px;
    }
  }

  .heading-aligner {
    text-align: left;
    padding: 2px 6px;
  }

  #services-editor-preamble {
    width: 720px;
    height: 48px;
    text-align: left;
    margin-top: 12px;
    padding: 2px 6px;
    font-family: 'VWText';
    font-size: 14px;
    line-height: 1.71;
    color: $text_primary_color;
  }

  #driveview-hint {
    font-family: 'VWText';
    font-size: 12px;
    margin-top: 33px;
    text-align: left;
    line-height: 1.67;
    color: #6a767d;
  }

  .service-feature-heading {
    font-family: 'VWText';
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    color: $newtheme_marine_dark;
  }

  .service-feature-description {
    width: 300px;
    height: 80px;
    font-family: 'VWText';
    font-size: 12px;
    line-height: 1.67;
    color: #6a767d;
  }

  #services-editor-heading {
    font-family: 'VWHeadLight';
    font-size: 26px;
    font-weight: 300;
    line-height: 1.2;
    color: $text_primary_color;
  }

  button.dropdown-toggle {
    width: 75%;
    background-color: #fff !important;
    height: 30px !important;
    text-align: right !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .buttonToTheRight {
    float: right;
    margin-right: -15px;
    margin-top: 20px;
  }

  .buttonToTheLeft {
    float: left;
  }

  .margin-top-10 {
    margin-top: 10px;
  }
}

.container-fluid {
  margin-right: 1px !important;
  margin-left: 1px !important;
  margin-top: 40px;
}
.col-md-9 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.col-md-3 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.col.toolTipWrappingOuterElement .toolTipWrapper {
  display: inline-block;
  margin: 0;
  margin-left: 5px;

  .hoverState {
    display: none;
    width: 300px;

    &.show {
      display: block;
      margin-right: -42px;
    }

    &:focus {
      outline: none;
      border: 3px solid $link_text_color;
    }
  }
}

.acctmgmt-contentpane {
  font-family: 'VWText';
  padding: 0px 10px 40px 10px;
  border: 1px ridge $newtheme_marine_dark;
  width: 97%;

  .overall-consent-radio-label {
    font-family: 'VWText';
    font-size: 18px;
    font-weight: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $text_primary_color;
  }

  #consents-radio-buttons {
    fieldset {
      #accept-radio-option {
        margin-left: 4em;
      }

      #decline-radio-option {
        margin-left: 4em;
      }
    }
  }

  .consent-title {
    font-size: 20px;
    color: $text_primary_color;
  }

  .consent-copy {
    max-width: 720px;
    width: auto;
    font-family: 'VWText';
    font-size: 14px;
    line-height: 1.38;
    color: $text_primary_color;
    padding: 10px 2px;
    border-bottom: 1px groove $newtheme_hint;
  }

  .vertical-spacer {
    height: 20px;
    width: 100%;
  }

  .row {
    padding: 20px 10px;
    text-align: left;
    color: $text_primary_color;
    border-bottom: 1px ridge $newtheme_hint;
    &.borderless {
      border-bottom: none;
      padding: 10px 0;
      &.normalized {
        padding: 10px;
      }
    }
    &.row-soft-underline {
      border-bottom: 1px groove #b1b1b1;
    }
    .bootstrap-checkbox-label {
      padding-left: 14px;
      color: $newtheme_marine_dark;
    }

    .additional-driver-links {
      font-weight: lighter;
      color: $text_primary_color;
      font-size: 11pt;
    }

    .add-more-drivers-link {
      font-weight: bold;
      color: $newtheme_marine_dark;
      font-size: 12pt;
      text-decoration: underline;
      cursor: pointer;
      &.smaller {
        font-size: 11px;
        line-height: 12px;
      }
    }

    .challenge-question-preview {
      margin: 2px 0 0 0;
      padding: 0;
      &.marginBottom20 {
        margin-bottom: 20px;
      }
    }

    .acctmgmt-dropdown {
      max-width: 200px;
      height: 60px;
    }

    #health-report-indicator-aligner {
      width: 191px;
      height: 24px;
      padding-top: 20px;
      #health-report-indicator {
        height: 24px;
        font-family: 'VWText';
        font-size: 14px;
        line-height: 1.71;
        color: $text_primary_color;
      }
    }

    .editable-field-parent {
      position: relative;
    }

    .field-editor-panel-ctrlstrip {
      display: inline-block;
      position: absolute;
      top: 35px;
      left: 5px;
      z-index: 999;
      padding: 4px 8px;
      background-color: lightgray;
      border: 2px ridge white;
    }

    .editor-panel-ctrl {
      height: 20px;
      width: 20px;
      font-size: 10px;
      font-weight: bold;
      color: $text_primary_color;
      background-color: #707070;
      margin: 3px 8px;
      border: 1px ridge $text_primary_color;
      :active {
        background-color: $text_primary_color;
        color: white;
      }
    }

    .carnet-theme-field-label {
      font-weight: 900;
      text-align: left;
      font-size: 10pt;
      font-family: 'VWText';
    }

    .carnet-theme-field-value {
      font-family: 'VWText';
      text-align: left;
      font-weight: normal;
      ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        li {
          line-height: 1.25;
        }
      }
      pre {
        white-space: pre-line !important;
      }
      &.vi-data {
        font-family: 'VWText';
        font-weight: lighter;
        font-size: 13px;
        line-height: 1.71;
        color: $text_primary_color;

        &.grayish {
          color: $text_label_dark_color;
        }
      }
      &.radioSelection {
        font-size: 12px;
        color: $primary_color;
      }
      &.erroneous {
        color: $remove_item_color;
      }
    }

    #emergency-contact-name {
      font-size: 14px;
      line-height: 1.71;
      color: $text_primary_color;
    }

    .emergency-contact-meta {
      color: $data_subdued;
      line-height: 1.71;
    }

    .theme-updatelink {
      color: $newtheme_accent;
      cursor: pointer;
      &:hover {
        font-weight: bold;
      }
      &:disabled,
      &.disabled {
        color: $june_designchange_newhdrsnlabels;
        cursor: default;
        &:hover {
          font-weight: normal;
        }
      }
    }

    .greyed-out-link {
      color: $newtheme_hint;
      cursor: pointer;
    }

    .personal-info-fone-fields {
      list-style-type: none;
      .pi-summary-phonenums-data {
        line-height: 1.4;
        padding: 4px 2px;
      }
    }

    .personal-info-multivalue-cell {
      list-style-type: none;
      line-height: 1.5;
      padding: 0;
    }
  }

  .contentpane-title {
    text-align: left;
    padding: 20px 30px 20px 0px;
    border-bottom: solid 1px $newtheme_hint;
    margin: 0px;
    header {
      font-family: 'VWHead';
      font-size: 16px;
      font-weight: 300;
      line-height: 1.2;
      color: $text_primary_color;
    }
  }
}

#lns-panel {
  height: 600px;
}

div.editing-pane {
  width: 100%;
  height: 400px;
  border: 1px groove silver;
}

.editor-firstrow-label-aligner {
  padding-right: auto;
  padding-bottom: 1em;
  vertical-align: top;
  text-align: left;
  width: 90%;
  border: none;
  border-bottom: 1px solid $box_border_sides_color;
  margin-bottom: 20px;
}

.first-editor-field-label {
  font-family: 'VWHead';
  font-size: 20px;
  font-weight: 100;
  line-height: 1.2;
  color: $text_primary_color;
}

.editor-diatom-field.phone-field {
  border-bottom: none;
}

.quad_cells_row {
  width: 100%;
  padding: 6px 4px;
  margin-top: 14px;
  margin-bottom: 6px;
  text-align: left;
  span {
    font-size: 12px;
    text-align: left;
    color: #6a767d;
  }
}

.editor-diatom-field {
  width: 100%;
  padding: 0px;
  padding-top: 0px;
  text-align: left;

  .form-text {
    height: 24px;
    margin-top: 0.1rem;
    margin-bottom: 5px;
    border-bottom: 1px solid $hint_grey !important;

    &.invalid_input {
      border-color: $remove_item_color;
    }
  }

  .cwp {
    height: 24px;
    margin-top: 0.1rem;
    margin-bottom: 5px;
    border: none;
    border-bottom: 1px solid $form_field_underline;
    border-radius: 0;

    &.pin-entry {
      display: inline-block;
      width: 200px;
    }

    &.invalid_input {
      border-color: $remove_item_color;
    }
  }

  .inline {
    display: inline-block;
  }

  .reverseLeftMargin {
    margin-left: 0px;
  }

  .form-text.pin-input {
    width: 5em;
    max-width: 5em;
    float: left;
    margin-right: 8px;
    border-bottom: 1px solid $hint_grey !important;
  }

  .paired-device-name {
    font-family: 'VWText';
    font-size: 10px;
    line-height: 1.71;
    color: $text_primary_color;
  }

  .concierge-pilot-enrollment {
    width: 50px;
    height: 24px;
    font-family: 'VWText';
    font-size: 12px;
    line-height: 1.71;
    color: $text_primary_color;
    .concierge-pilot-enrolled-indicator {
      font-weight: normal;
      color: #303030;
      font-size: 12px;
    }
  }

  .editor-diatom-superscript-lbl {
    vertical-align: top;
    font-family: 'VWText';
    font-size: 12px;
    line-height: 1.33;
    padding: 0;
    margin: 0;
    color: $june_designchange_newhdrsnlabels;
  }

  .invalid-pin-flag-indicator {
    font-family: 'VWText';
    font-weight: bold;
    font-size: 12px;
    line-height: 1.33;
    color: $remove_item_color;
    display: block;
    width: 200px;
  }
}

.pinEditorRow {
  .pin-reset-success {
    vertical-align: top;
    font-family: 'VWText';
    font-weight: bold;
    font-size: 12px;
    line-height: 1.33;
    margin-right: 38px;
    color: $text_success_color;
  }

  .pin-reset-failure {
    vertical-align: top;
    font-family: 'VWText';
    font-weight: bold;
    font-size: 12px;
    line-height: 1.33;
    margin-right: 38px;
    color: $remove_item_color;
  }

  .extraRight {
    margin-left: 50px;
  }
}

.carnet-theme-btn {
  background-color: $june_designchange_newhdrsnlabels !important;
  color: white !important;
  font-family: 'VWText';
  font-size: 16px;
}

.carnet-hint-theme-btn {
  background-color: #b1b1b1 !important;
  width: 90px;
  color: #454545 !important;
  font-family: 'VWText';
  font-size: 10px !important;
  border-radius: 10px !important;
}

.rounded-theme-btn {
  background-color: $june_designchange_newhdrsnlabels !important;
  color: white !important;
  font-family: 'VWText';
  font-size: 16px;
  border-radius: 26px !important;
}

.marginRight40 {
  margin-right: 40px;
}

#update-uername-instructions-blurb {
  font-family: 'VWText';
  font-size: 14px;
  color: $text_primary_color;
  height: 24px;
}

.btn-secondary {
  background-color: white !important;
  color: #001e50 !important;
}

.design-deviation-field-label {
  font-family: 'VWText';
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  color: $text_primary_color;
}

#remove-phone-link-aligner {
  text-align: left;
  padding: 0px 8px 0px auto;

  #remove-phone-link {
    font-family: 'VWText';
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    color: $remove_item_color;
    cursor: pointer;
  }
}

#address-lines-spacer {
  height: 20px;
  border: none;
}

.vertical-spacer-row {
  height: 140px;
  width: 100%;
  border: none;
}

.vertical-spacer-small {
  height: 40px;
  width: 100%;
  border-color: none !important; /* CHROME not honoring WWW3 rules. Always goes insane and creates an uncalled-for BORDER!!! */
  border: none !important;
}

.vertical-spacer-tiny {
  height: 25px;
  width: 100%;
  border-color: none !important; /* CHROME not honoring WWW3 rules. Always goes insane and creates an uncalled-for BORDER!!! */
  border: none !important;
}

.find-dealer-map-aligner {
  width: 100%;
  text-align: left;
  border: none;
  padding: 0px auto 0px 0px;
}

.carnet-sg-modal {
  .sirius-detail-modal {
    .acctmgmt-sirius-details-list {
      list-style-type: none;

      .acctmgmt-sirius-detail {
        list-style-type: none;
        font-size: 16px;
        color: white;
        font-weight: normal;
        text-align: left;

        .acctmgmt-sirius-detail-datum {
          font-weight: bolder;
        }
      }
    }
  }
}

.acctmgmt-disclaimer {
  height: 20px;
  width: 100%;
  margin: auto;
  padding: 15px 0 0 0;
  text-align: left;
  .guardian-role-disclaimer {
    font-size: 10pt;
    padding-left: 20px;
  }
}

.acctgmt_wifi_config_explanation_text {
  font-family: 'VWText';
  text-align: left;
  font-weight: normal;
  font-size: 10px !important;
  line-height: 1.71;
  color: $text_label_dark_color !important;
}

.extendedCalendarHeight {
  height: 340px;
}

.erroneous {
  color: $remove_item_color;
}

div.add-phone-link-item:focus,
span.remove-phone-link:focus,
span.dlr-name-text:focus,
div.removePhoneLink:focus,
button.modal-secondary-btn:focus,
button.modal-primary-btn:focus {
  outline: 3px solid $focus_outline_color;
}

.modal-primary-btn {
  min-width: 7rem;
  font-family: VWText;
  font-size: 16px;
}

legend {
  font-size: 1rem;
}

.addDriver {
  width: 15px;
  height: 15px;
}
