@import '../../../../node_modules/bootstrap/scss/bootstrap';
@import '../colorVariables.scss';

.custom-radio {
  .custom-control-input {
    :checked {
    }
  }
}

.form-input-label {
  font-size: 8pt;
  color: $text_primary_color;
}

.radioLabelText {
  font-size: 14px;
  font-family: VWText;
  color: $text_primary_color;
}
