@import '../../reusable_cmp_lib/colorVariables.scss';

.driveview-scss-insulator {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: auto;
  min-height: 400px;

  .driveViewComponentHeading {
    text-align: center;
    font-family: VWHead;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: $text_primary_color;
    padding-top: 30px;
  }

  .driveViewComponentSubHeading {
    font-family: VWText;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $text_label_dark_color;
    margin: 10px 0;
  }

  .driveViewComponentScoreValue {
    font-family: VWHead;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: $text_primary_color;
    position: absolute;
    top: 36%;
    left: 28%;
    transform: translate(-50%, -50%);
  }

  .driveViewComponentSectionHeading {
    text-align: left;
    font-family: VWHead;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $text_primary_color;
    width: 87%;
    max-width: 1200px;
    margin: 10px auto;
  }

  .nullDriveViewScore {
    text-align: center;
    max-width: 480px;
    margin: auto;
    padding: 20px 5px;

    img {
      padding-top: 20px;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .driveViewComponentSnapShotInfo {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    padding-left: 50px;
  }

  /*
  .driveViewScoreReading {
    width: 250px;
    max-width: 250px;
    display: inline-block;
    padding: 0;
    margin: 0;

    span {
      position: absolute;
      padding: 0;
      margin: 0;
      margin-top: 90px;
      font-family: VWHead;
      font-size: 44px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: center;
      color: $text_primary_color;

      &.doubleDigits {
        margin-left: 95px;
      }

      &.singleDigit {
        margin-left: 115px;
      }

      &.tripleDigits {
        margin-left: 85px;
      }
    }

    img {
      width: 250px;
      height: auto;
      padding: 0;
      margin: 0;
    }
    .infoIcon {
      margin: 0 0 100px 230px;
      position: absolute;
      width: 25px;
      height: 25px;
      max-width: 25px;
      max-height: 25px;
      background-size: 100%;
      background-image: url('../../../assets/icons/info-filled.svg');
    }
  }
  */

  .driveViewComponentSnapShot {
    width: 900px;
    padding: 0;
    margin: auto;

    .driveViewComponentSnapShotPadding {
      margin-top: 65px;
      margin-left: 70px;
    }

    .textSize {
      font-family: VWText;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $text_label_dark_color;
    }

    .textSize {
      font-family: VWText;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $text_label_dark_color;
    }

    .numberSize {
      font-family: VWText;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: $primary_color;
    }
  }

  .driveViewComponentInsuranceMessagesInfo {
    width: 900px;
    margin: auto;
    margin-top: 20px;
    display: -ms-flexbox;
    display: flex;

    .driveViewComponentSnapShotPadding {
      margin-left: 40px;
      margin-top: 30px;
    }

    .adBox {
      min-height: 72px;
      height: auto;
      width: 373px;
      padding-left: 20px;
      background: $background_color;
      border-radius: 5px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      cursor: pointer;

      &:hover {
        .learnMoreText {
          text-decoration: underline;
        }
      }

      .expandedText {
        width: 100%;
        font-size: 0.8em;
        padding-top: 70px;
        text-align: left;

        span {
          display: block;
        }

        .contactInfo {
          font-weight: bold;
          font-size: 0.78em;
          font-style: italic;
        }
      }
    }

    .adImageSize {
      height: 45px;
      margin-top: 15px;
      float: left;
    }

    .learnMoreText {
      font-family: VWText;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: right;
      color: $primary_color;
      height: 45px;
      margin-top: 20px;
      margin-right: 20px;
      float: right;
    }

    .rightArrowImageSize {
      height: 25px;
    }
  }

  .driveViewComponentAggregatedSnapshot {
    width: 100%;
    height: 452px;
    object-fit: contain;
    padding-top: 40px;
    background-color: $article_background_color;
    margin: auto 0;

    .selectedTypeBackground {
      width: 120px;
      height: 32px;
      border-radius: 16px;
      background-color: $primary_color;
      color: $background_color;
      text-align: center;
      cursor: pointer;
    }

    .notSelectedTypeBackground {
      width: 120px;
      height: 32px;
      border-radius: 16px;
      background-color: $background_color;
      color: $primary_color;
      text-align: center;
      cursor: pointer;
    }
  }

  .driveViewComponentCTSDriverScore {
    margin-left: 100px;
  }

  .container {
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center {
    width: 33.33vw;
  }

  .dateSpacing {
    margin-left: 90px;
    margin-right: 90px;
    font-family: VWHead;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: $text_primary_color;
  }

  .driverScoreContainer {
    display: -ms-flexbox;
    display: flex;
    justify-content: left;
    align-items: left;
  }

  .dateImageSize {
    width: 25px;
    height: 25px;
  }

  .driveComponentSectionPadding {
    padding-top: 40px;
  }

  .driveComponentCTS {
    .container-fluid {
      margin-top: 0px;
    }

    .col-sm-4 {
      margin: 10px 20px 0 40px;
      width: 412px;
      min-width: 400px;
    }

    .background {
      height: 107px;
      border-radius: 5px;
      box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.12);
      background-color: $background_color;
      border-left: 8px solid $primary_color;
    }

    .vl {
      border-left: 1px solid $text_label_dark_color;
      height: 75px;
    }

    .titleSize {
      font-family: VWText;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: $text_primary_color;
    }

    .descSize {
      font-family: VWText;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      padding-top: 15px;
      color: $text_primary_color;
    }

    .percentage {
      font-family: VWText;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      padding-top: 15px;
      color: $primary_color;
    }

    .imageCenter {
      text-align: center;
    }
  }

  .driveViewComponentCTSScoreValue {
    font-family: VWHead;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: $text_primary_color;
    position: absolute;
    top: 100%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  .viewTripTipsSection {
    .dateTDWidth {
      width: 15%;
    }

    .driveScroreTDSize {
      width: 250px;
      height: 250px;
    }

    .headingSection {
      font-family: VWHead;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: $text_label_dark_color;
    }

    .background {
      width: 285px;
      height: 117px;
      object-fit: contain;
      border-radius: 5px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      background-color: $background_color;
      padding: 10px;
    }

    .heading {
      font-family: VWHead;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: $text_primary_color;
      padding-left: 21px;
    }

    .headingTextSize {
      font-family: VWText;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $text_label_dark_color;
    }

    .AMPMSize {
      font-family: VWHead;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: $text_primary_color;
    }

    .dateFormatSize {
      font-family: VWText;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: $text_primary_color;
    }

    .driveViewComponentScoreValue {
      font-family: VWHead;
      font-size: 44px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: center;
      color: $text_primary_color;
      position: absolute;
      top: 37%;
      left: 25%;
      transform: translate(-50%, -50%);
    }

    .ml-auto,
    .mx-auto {
      margin-left: auto !important;
      text-align: center;
      margin-top: 32px;
    }

    .sectionPadding {
      margin-top: 40px;
    }
  }

  .driveViewComponentNoIM {
    width: 1186px;
    height: 72px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-color: $background_color;
    justify-content: center;
    margin: auto;
    text-align: center;
    padding-top: 20px;
    margin-top: 25px;
  }

  .driveViewComponentNotEnrolled {
    width: 1186px;
    height: 350px;
    margin: auto;
    text-align: center;
    padding-top: 20px;
    margin-top: 25px;

    .textSize {
      width: 1180px;
      height: 69px;
      font-family: VWHead;
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: center;
      color: $text_primary_color;
    }
  }

  .geicoWrapper {
    margin-top: 20px;
    text-align: left;
    width: 100%;
    padding-left: 15%;

    .rowgeico {
      display: inline-block;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      width: 588px;
      height: 233px;
      /*margin-left: 356px;*/
      margin-top: 50px;

      button {
        margin-bottom: 10px;
      }
    }

    .disclaimer {
      font-size: 8px;
      color: #6a767d;
      text-align: left;

      margin-left: 30px;
      width: 100%;
    }
  }

  .quoto {
    padding-bottom: 2px;
    border-bottom: 1px solid #001e50;
    line-height: 39px;
  }

  .geico-header {
    color: #000000;
    text-align: left;
    font-weight: bold;
    margin-top: 20px;
  }

  .geico-subheader {
    color: #000000;
    text-align: left;
    font-weight: bold;
    margin-top: 7px;
    font-size: 12px;
  }

  .geico-link {
    text-align: left;
    margin-top: 20px;
  }

  .geico-content {
    text-align: left;
    margin-top: 10px;
    font-size: 13px;
    margin-top: 17px;
    width: 280px;
  }

  .columngeico-left {
    float: left;
    padding: 6px;
    width: 60%;
  }

  .columngeico-right {
    float: left;
    padding: 6px;
    width: 40%;
  }

  .columngeicoright {
    float: left;
    padding-right: 22px;
    padding-left: 273px;
  }

  .rowgeico:after {
    content: '';
    display: table;
    clear: both;
  }

  .advertisement-header {
    color: #6a767d;
    font-weight: bold;
    margin-bottom: -35px;
  }
}

.modal-dialog.carnet-sg-modal.driveViewInsOffer .modal-content .modal-footer {
  width: 100%;

  div {
    width: 100%;
    margin: 10px 0 0 0;
    padding: 0;
    text-align: center;
  }

  button.modal-primary-btn.btn.btn-modal_background_color,
  button.modal-secondary-btn.btn.btn-modal_background_color {
    width: 80% !important;
    margin: auto;
    display: block;
  }
}

.driveViewScoreReading {
  width: 250px;
  max-width: 250px;
  display: inline-block;
  padding: 0;
  margin: 0;

  span {
    position: absolute;
    padding: 0;
    margin: 0;
    margin-top: 90px;
    font-family: VWHead;
    font-size: 44px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: $text_primary_color;

    &.doubleDigits {
      margin-left: 95px;
    }

    &.singleDigit {
      margin-left: 115px;
    }

    &.tripleDigits {
      margin-left: 85px;
    }

    &.noData {
      margin-left: 95px;
      font-size: 16px !important;
    }
  }

  img {
    width: 250px;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .infoIcon {
    margin: 0 0 100px 230px;
    position: absolute;
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
    background-size: 100%;
    background-image: url('../../../assets/icons/info-filled.svg');
    cursor: pointer;
  }
}

.driveViewPrevNext {
  cursor: pointer;
  margin-left: -8%;
  margin-right: -9%;
}

.driveview-viewtrip-modal {
  max-width: 1200px;
  border-bottom: 0px;
}

.driveview-viewtrip-modal-exit {
  background-image: url('../../../assets/tripStats/close.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  float: right;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.driveview-modal-tips {
  font-family: VWHead;
  font-size: 22px;
  font-weight: bold;
  color: $text_primary_color;
}

.driveview-modal-tips-heading {
  font-family: VWText;
  font-size: 16px;
  font-weight: bold;
  color: $data_subdued;
}

.driveview-modal-tips-background {
  max-width: 285px;
  height: 117px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  padding: 10px 15px 10px 14px;
}

.driveview-modal-tips-desc {
  font-family: VWText;
  font-size: 13px;
  color: $text_primary_color;
  margin: 5px 0 0 1px;
}

.driveViewViewTripWiseBackground {
  width: 120px;
  height: 122px;
  border-radius: 5px;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.12);
  background-color: $background_color;
  border-top: 45px solid $primary_color;
  padding: 6px;
  text-align: center;

  .imageMargin {
    margin-top: -140px;
    margin-left: -23%;
  }

  .textSize {
    font-family: VWText;
    font-size: 12px;
    text-align: center;
    color: $text_label_dark_color;
  }

  .numberSize {
    font-family: VWText;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: $primary_color;
  }

  .headingCenter {
    text-align: center;
  }
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
  text-align: center;
  margin-top: 20px;
}

.driveViewTripStatsBtn {
  width: 148px;
  background-color: $background_light_color;
  color: $primary_color;
  font-size: 16px;
  height: 44px;
  border-radius: 24px;
  border: 2px solid $primary_color;
  box-shadow: 2px 1px 2px #ccc;
  margin-bottom: 20px;
}

.driveViewTripStatsDiv {
  float: right;
  margin-right: 125px;
  margin-left: -18%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .driveComponentCTS {
    .container-fluid {
      margin-top: -15px !important;
    }

    .col-sm-4 {
      margin: 1px 7px 0 71px !important;
      width: 220px !important;
    }
  }

  .driveViewComponentAggregatedSnapshot {
    width: auto !important;
  }
}

@media (min-width: 576px) {
  .driveview-scss-insulator .driveComponentCTS .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 760px) {
  .driveViewComponentAggregatedSnapshot {
    min-width: 1300px;
  }
}

@media only screen and (max-width: 760px) {
  .driveview-scss-insulator .driveViewComponentSnapShot .driveViewComponentSnapShotPadding {
    margin-left: 0px;
    margin-top: 12px;
    width: auto;
  }

  .driveview-scss-insulator .driveViewComponentAggregatedSnapshot {
    background-color: #f9f9f9;
    height: auto;
    margin: auto 0;
    object-fit: contain;
    padding-top: 40px;
    width: 100%;
    text-align: center;
  }

  .driveview-scss-insulator .driveViewComponentSnapShotInfo {
    display: block;
    padding-left: 0px;
    width: 100%;
  }

  .driveview-scss-insulator .driveViewComponentSnapShot {
    margin: auto;
    padding: 0;
    width: auto;
  }

  .row {
    display: inline !important;
  }

  .driveview-scss-insulator .driveViewComponentNoIM {
    width: 95%;
  }

  .driveview-scss-insulator .driveComponentCTS .col-sm-4 {
    margin: 0 auto;
    width: 407px;
  }

  .driveview-scss-insulator .center {
    width: 75%;
  }

  .geicoWrapper {
    margin-top: 20px;
    text-align: left;
    width: 100%;
    padding-left: 5% !important;

    .disclaimer {
      font-size: 8px;
      color: #6a767d;
      text-align: left;
      margin-left: 30px;
      width: 100%;
    }
  }

  .driveview-scss-insulator .driveViewComponentSectionHeading {
    font-size: 16px;
    margin-left: 12px;
    margin-top: 25px;
  }

  .driveview-scss-insulator .driveComponentCTS .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.geicoModal .modal-footer {
  justify-content: center;
  border-top: 0px;
}

.ubi-terms {
  .we-have-updated {
    width: 276.8px;
    font-family: VWHead;
    font-size: 24px;
    font-weight: bold;
    margin-left: 11%;
  }

  .we-are-always {
    margin-left: 11%;
    margin-top: 3%;
  }

  .radio-btn-text {
    color: $carnet_root_color;
  }

  .continue-btn {
    font-weight: bold;
    color: $button_primary_text;
  }

  .tos-text {
    text-decoration: underline;
  }

  .grid-center {
    display: grid;
    place-items: center;
  }
}
