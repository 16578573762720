/* Medium devices (landscape tablets and destop, 768px and up) */
@media only screen and (min-width: 768px) {
  .styles_faq-row-wrapper__3vA1D {
    padding-left: 45px;
    padding-right: 45px;
    width: 800px;
    margin: 0 auto;
  }
}
/* Medium devices ( tablets and phone, 767px and down) */
@media only screen and (max-width: 767px) {
  .styles_faq-row-wrapper__3vA1D {
    padding-left: 45px;
    padding-right: 45px;
    width: 90%;
    margin: 0 auto;
  }
  .download {
    margin: 11px;
    text-align: center;
    font-family: 'VWTextBold';
    display: flex;
    width: 80%;
  }
}

.td-ms-header {
  text-align: center;
}

.faq-sub-header {
  font-size: 15px;
  color: #000;
  margin-top: 20px;
  line-height: 25px;
  text-align: center;
}
.iconfaqact {
  margin-top: -11px;
  margin-left: -3px;
}
.viewfaq {
  text-align: end;
  margin-right: 34px;
}
.faqicon {
  background-image: url('../../../assets/faq/Icon.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.faq-head {
  font-size: 18px;
  color: black;
  //padding-top: 14px;
  text-align: center;
  font-weight: bold;
}
.faq-header {
  font-size: 30px;
  color: #000;
  text-align: center;
  font-family: 'VWText';
}
.faq-back-header {
  font-family: 'VWTextBold';
}
.download {
  font-size: 13px;
  margin: 8px;
  text-align: center;
  font-family: 'VWTextBold';
}
.first-row {
  text-align: center;
  padding: 10px;
  width: 380px;
  height: 192px;
}
.first-row-data {
  margin: 0 auto;
  margin-bottom: 5rem;
}
.help {
  border: 1px solid lightgray;
  text-align: center;
  background-color: white;
  height: 210px;
  width: 345px;
  margin-top: 20px;
}
.article-title {
  margin-top: 8px;
  font-size: 13px;
  height: 70px;
}
.help-img {
  margin-top: 20px;
}
.faqs {
  width: 80%;
  margin: 0 auto;
  padding-top: 4rem;
}
.faqs .faq {
  margin: 15px;
  line-height: 32px;
}
.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  font-family: 'VWTextBold';
  transition: all 0.4s ease;
}
.faqs .faq .faq-question::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-image: url('../../../assets/faq/chevron.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s ease-out;
}
.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
  font-family: 'VWText';
}
.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}
.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}
.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
  font-family: 'VWText';
}

.gobackfaq {
  text-align: left;
  margin-bottom: -17px;
}

.faqs .faq .faq-answer-other {
  opacity: 0;
  font-family: 'VWText';
  max-height: 0;
  margin-top: -15px;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-answer-other {
  max-height: 1000px;
  opacity: 1;
  font-family: 'VWText';
}
.qrg {
  margin-left: 40px;
  font-size: 16px;
}

.account-faq-disclaimers {
  font-size: 9px;
  margin-left: 5em;
  margin-right: 20em;
  margin-top: 7em;
  .disclaimers-title {
    font-family: 'VWHeader';
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 1em;
  }
  .faq-disclaimer {
    margin-bottom: 1em;
    font-size: 11px;
    span.citation-superscript {
      vertical-align: super;
      font-weight: 900;
      font-size: 12px;
    }
  }
}

.message-header {
  text-align: center;
  width: 100%;
  font-size: 24px;
  margin-bottom: 38px;
}

.underline {
  text-decoration: underline;
}
