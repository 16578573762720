@import '../../reusable_cmp_lib/colorVariables.scss';

.toolTipWrapper {
  position: absolute;
  z-index: 3;
  margin-top: 22px;
  margin-left: 235px;

  .info-icon {
    width: 24px;
    height: 24px;
    background: #fff;
    cursor: pointer;
    img:focus {
      margin-left: -1px;
      width: 27px;
      height: 27px;
      border: 3px solid $link_text_color;
      border-radius: 15px;
    }
  }

  .hoverState {
    display: none;
    width: 300px;
    padding: 0;
    margin-top: -74px;
    margin-left: -235px;

    &.show {
      display: block;
    }

    &:focus {
      outline: none;
      border: 3px solid $link_text_color;
      margin-top: -80px;
    }

    .bubble {
      display: block;
      width: 100%;
      height: 64px;
      min-height: 64px;
      color: #fff;
      background-color: $modal_background_color;
      margin: 0;
      padding: 5px;
      font-size: 14px;
      line-height: 15px;
    }

    .triangle-down {
      display: block;
      margin-left: 79%;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $modal_background_color;
    }
  }
}
