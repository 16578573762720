@import '../../reusable_cmp_lib/colorVariables.scss';

.batteryInfo {
  margin-left: 12%;
  margin-right: 12%;
  min-height: calc(100vh - 90px);

  .form-group {
    width: 20%;
    margin: 10px 3%;
    display: flex;
    flex-direction: column;
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .submit-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 18px;
    color: $button_primary_text;
    border: none;
    cursor: pointer;
    width: 255px;
    height: 48px;
    border-radius: 30px;
    text-align: center;
    font-family: VWText;
  }

  .submit-button:hover {
    background-color: #004080;
  }

  .submit-disable-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 18px;
    color: $button_primary_text;
    border: none;
    cursor: pointer;
    width: 255px;
    height: 48px;
    border-radius: 30px;
    text-align: center;
    background-color: $button_disabled_face;
    font-family: VWText;
  }

  .form-container {
    display: flex;
    flex-wrap: wrap;
    margin: 3% 0;
  }

  .hr-line {
    width: 100%;
    border: solid 1px $form_field_underline;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .error-message {
    text-align: center;
    margin-bottom: 20px;
  }

  .download-link {
    color: $carnet_root_color;
  }

  .lookup-error {
    font-size: 18px;
    font-weight: bold;
    color: $remove_item_color;
    text-align: center;
    margin-top: 4%;
  }

  @media (max-width: 768px) {
    .form-group {
      width: 85%;
      margin: 10px 2.5%;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }

  th,
  td {
    padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
    width: 30%;
  }

  th {
    background-color: $carnet_root_color;
    color: $modal_text_color;
    text-align: center;
    font-size: 20px;
  }

  tr:nth-child(even) {
    background-color: #dfdfdf;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #e8f0fe;
  }
}
