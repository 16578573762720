@import '../../reusable_cmp_lib/colorVariables.scss';

.sunset-3g {
  height: 282px;
  margin: 0 auto;
  width: 100%;
}

.sunset-3g-desc {
  height: 282px;
  margin: 0 auto;
  width: 100%;
  background-color: #dfe4e8;
}

.img-learnmore {
  margin-top: -2%;
  margin-left: 95px;
}
.cwplandingPage-2019 {
  border-style: none;
  color: #001e50;
  font-size: 1rem;
  line-height: 1rem;
  border-radius: 22px;
  outline: none;
  width: 169px;
  height: 44px;
}
.trial {
  vertical-align: top;
  height: 282px;
  padding-top: 40px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.trial-desc {
  font-size: 60px;
  font-family: 'VWTextBold';
  line-height: 57px;
}

.icons {
  width: 100%;
  text-align: center;
}
.iconsDesc {
  font-size: 28px;
  font-family: 'VWTextBold';
}

.remote-data {
  width: 100%;
}

.Video-rectangle {
  width: 100%;
}
.videolink {
  height: 272px;
  width: 33.33%;
}
.cwp-header-old {
  background-image: url(../../../assets/landingPageOld/white.png);
  background-size: 143px;
  background-position: 61px;
  background-repeat: no-repeat;
  height: 84px;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 2;
  background-color: white;
  top: 0px;
}

.cwp-header-old #vwlogo {
  background-color: $background_color;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  background-image: url(../../../assets/images/vwlogo.svg);
  background-size: 48px;
  background-position: center;
  background-repeat: no-repeat;
  width: 68px;
  height: 68px;
  position: absolute;
  right: 33%;
  top: 40px;
}

.cwp-header-old hr {
  border: 1px solid $carnet_root_color;
}
.landingnavbar {
  overflow: hidden;
  background-color: #fff;
  top: 83px;
  width: 100%;
  z-index: 1;
}
.top-left-old {
  top: -135px;
  position: relative;
  left: 20px;
}
.videolink-res {
  height: 214px;
  width: 100%;
}
.icons-res {
  width: 100%;
  text-align: center;
  margin-top: 62px;
  margin-bottom: 70px;
}
.trial-desc-res {
  font-size: 28px;
  font-family: 'VWTextBold';
}
.trial-res {
  height: 360px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
}
.top-left-old-res {
  margin-top: -130px;
  margin-left: 20px;
}

//changes for 3g turn down

.VWHead-light-vzt {
  font-size: 52px;
  color: #fff;
  font-family: 'VWHeadLight';
}
.VWHead-bold-vzt {
  font-size: 50px;
  color: #fff;
  font-family: 'VWTextBold';
}

.turndown-img {
  margin-top: -9px;
}
.turndown {
  width: 100%;
  height: 296px;
  margin-top: -48px;
  color: #fff;
  background-image: radial-gradient(circle at -11% -21%, #00437a, #001e50 60%);
}
.turndown-span {
  font-size: 18px;
  color: #fff;
}
.vinlookup {
  text-align: center;
  margin-top: 50px;
}

.vin .modal-content {
  left: 50%;
  top: 45%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  width: 855px;
  height: 160px;
}
.vinCursorPointer {
  cursor: pointer;
  margin-left: 160px;
  margin-top: -10px;
}
.vinlookup-header {
  font-size: 48px;
  font-family: 'VWTextBold';
  margin-bottom: 30px;
}

.vinlookup-sub-header {
  font-size: 20px;
  font-family: VWText;
}

.vin-cwp-input {
  background-repeat: no-repeat;
  background-position: calc(100% - 6px);
  font-size: 16px;
  border: 0px solid $text_input_border_color;
  padding-right: 30px;
  background-size: 24px;
  border-bottom: 1px solid $text_input_border_bottom_color;
  width: 295px;
  margin-right: 48px;
  padding-bottom: 12px;
  &.error {
    border-bottom: 1px solid #e4002c;
  }
}
.md-title {
  font-size: 16px;
  font-family: VWText;
  font-weight: normal;
}
.first-row-vin {
  width: 72%;
  float: right;
}
.sec-row-vin {
  width: 50%;
  text-align: left;
  padding-left: 36px;
}
.vinIneligible .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
  color: #fff;
  margin-left: 20px;
  font-size: 19px;
}

.vinIneligible .modal-header {
  font-family: VWHead;
  font-size: 26px;
  color: #fff;
  border-bottom: 0;
  margin-top: 35px;
  margin-bottom: 0px;
  margin-left: 20px;
}
.vinIneligible .modal-content {
  left: 50%;
  top: 45%;
  transform: translate(-50%);
  height: 380px;
  background-color: #5b08a4;
}
.vinIneligible .modal-footer {
  border-top: 0;
  margin-right: 56px;
  margin-bottom: 28px;
}
.vinlookupCPO .modal-body {
  width: 460px;
  height: 354px;
  margin-top: 20px;
  text-align: justify;
  margin-left: 20px;
  font-size: 15px;
}

.vinlookupCPO .modal-footer {
  display: block;
  padding: 5px;
  border-top: 0px solid #dee2e6;
  text-align: center;
}

.vinlookupCPO .modal-content {
  left: 50%;
  top: 45%;
  transform: translate(-50%, -0%);
  width: 500px;
  height: 220px;
}

.vinlookup .modal-body {
  width: 460px;
  height: 354px;
  margin-top: 20px;
  text-align: justify;
  margin-left: 20px;
  font-size: 15px;
}

.vinlookup .modal-footer {
  display: block;
  padding: 5px;
  border-top: 0px solid #dee2e6;
  text-align: center;
}

.vinlookup .modal-content {
  left: 50%;
  top: 45%;
  transform: translate(-50%, -0%);
  width: 500px;
  height: 220px;
}
.vinlookup-noteligible .modal-content {
  left: 50%;
  top: 45%;
  transform: translate(-50%, -0%);
  width: 500px;
  height: 260px;
}

.vinlookup-noteligible-1 .modal-content {
  left: 50%;
  top: 45%;
  transform: translate(-50%, -0%);
  width: 500px;
  height: 238px;
}

.vinlookup-noteligible .modal-footer {
  display: block;
  padding: 5px;
  border-top: 0px solid #dee2e6;
  text-align: center;
}

.vinlookup-noteligible .modal-body {
  padding: 2rem;
}
.vinlookupmdheader {
  font-family: VWHead;
  font-size: 20px;
  color: #000;
  font-weight: 700;
  border-bottom: 0;
  margin-top: -45px;
  margin-bottom: 5px;
  width: 90%;
}
.row-vin {
  width: 100%;
}
.humheader {
  font-size: 32px;
}
.vinlookupFPO .modal-content {
  left: 50%;
  top: 45%;
  transform: translate(-50%, -0%);
  width: 500px;
  height: 224px;
}
.vinlookupFPO .modal-body {
  width: 500px;
  height: 224px;
}
.feature {
  font-size: 12px;
}
.VWHead-detail {
  color: #fff;
  font-size: 20px;
}
.VWHead2014-light {
  font-size: 52px;
  color: #fff;
  line-height: 48px;
  font-family: 'VWHeadLight';
}
.VWHead2014-bold {
  font-size: 52px;
  color: #fff;
  font-family: 'VWTextBold';
}
.helptopic2014 {
  position: absolute;
  top: 10vw;
  left: 7%;
}
.helptopiclogo2014 {
  position: absolute;
  top: 2vw;
}
.turn-down-logo {
  width: 130px;
  margin-left: 46px;
  vertical-align: revert;
}
.turn-down-border {
  width: 100%;
}
.tooltip-vinlookup {
  left: 3px;
  top: -8px;
}
.vn-title {
  font-size: 32px;
}
.turndown-span-right {
  color: #fff;
}
.sunset-3g-td {
  width: 25%;
  margin-left: 32%;
  display: inline-block;
  margin-top: 10%;
  font-family: 'VWTextBold';
}
.sunset-3g-td-right {
  width: 75%;
  padding-right: 94px;
  text-align: justify;
}
.eligibility-link {
  color: #fff;
}

.eligibility-link-faq {
  color: #fff;
  text-decoration: underline;
}

.eligibility-img {
  background-color: #d8d8d8;
  width: 421px;
  height: 1px;
  display: block;
  margin-top: 2px;
}

.eligibility-link::after {
  color: #fff;
  content: '\2192';
}
.sunset-3g-td-res {
  margin-left: 3%;
  display: inline-block;
  margin-top: 10%;
  margin-right: 3%;
  font-size: 13px;
  line-height: 24px;
}

.vinlookupres .modal-content {
  left: 50%;
  top: 45%;
  transform: translate(-50%, -0%);
  width: 311px;
  height: 220px;
}
.vinlookup-noteligible-res .modal-content {
  left: 50%;
  top: 45%;
  transform: translate(-50%, -0%);
  width: 311px;
}
.vinlookup-header-res {
  font-size: 23px;
  font-family: 'VWTextBold';
}
.vinlookup-sub-header-res {
  font-size: 13px;
  font-family: 'VWHeadLight';
}
.turndown-res {
  height: 577px;
  margin-top: -48px;
  color: #fff;
  background-image: radial-gradient(circle at -11% -21%, #00437a, #001e50 60%);
  width: 100%;
}
.eligibility-img-res {
  background-color: #d8d8d8;
  width: 99px;
  height: 1px;
  display: block;
  margin-top: 2px;
}
.turndown-span-res {
  font-size: 18px;
  color: #fff;
  font-family: VWTextBold;
  margin-left: 10px;
  vertical-align: middle;
}
.eligibility-upgrade {
  font-family: VWTextBold;
}

.vinlookup-res .modal-footer,
.vinlookup-noteligible-res .modal-footer {
  display: block;
  padding: 0px;
  border-top: 0 solid #dee2e6;
  text-align: center;
}
@media (min-width: 768px) {
  .view-responsive {
    display: none;
  }

  .helptopic {
    width: 50%;
  }
  .hero-img {
    background-image: url(../../../assets/landingPageOld/hero.png);
    background-position: 0px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 500px;
  }
}

@media (max-width: 768px) {
  .view-non-responsive {
    display: none;
  }

  .sunset-3g-td-right {
    padding-right: 43px;
    text-align: justify;
    margin-left: 28px;
    display: flex;
    width: 95%;
    padding-top: 15px;
    margin-bottom: 24px;
  }

  .vinlookup-sub-header {
    font-size: 12px;
    font-family: VWText;
    text-align: center;
  }
  .vinlookup-header {
    font-size: 18px;
    font-family: 'VWTextBold';
    width: 80%;
    margin: 0 auto;
  }
  .landingPageWrapper input {
    padding-top: 30px;
    padding-bottom: 5px;
    margin-right: 0px;
  }
  .landingPageWrapper .checkEligiblityBtn.disabled {
    background-color: #dfe4e8;
    margin-top: 34px;
  }
  .landingPageWrapper .checkEligiblityBtn {
    margin-bottom: 8px;
    font-size: 1em;
    padding: 11px 34px 15px 34px;
    color: #fff;
    background-color: rgb(0, 30, 80);
    border: none;
    border-radius: 20px;
    margin-top: 25px;
  }
  .helptopic {
    position: absolute;
    top: 0vw;
    left: 7%;
  }
  .sunset-3g-td {
    width: auto;
    margin-left: 7%;
    display: inline-block;
    margin-top: 4%;
  }
  .view-responsive {
    margin-bottom: -45px;
    font-weight: bold;
  }

  .hero-img {
    background-image: url(../../../assets/landingPageOld/hero.png);
    background-position: 0px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 375px;
  }
  .__react_component_tooltip {
    width: 300px;
    background-color: #5b08a4 !important;
    font-size: 11px !important;
    text-align: left;
  }

  __react_component_tooltip::before,
  .__react_component_tooltip::after {
    content: '';
    border-bottom: 28px solid #5b08a4 !important;
    border-top-color: #5b08a4 !important;
    left: 42% !important;
  }
  .mdl-wd {
    width: 80%;
    display: block;
    margin: 0px 0px 0px 32px;
  }
}
