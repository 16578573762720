@import '../../reusable_cmp_lib/colorVariables.scss';

.formYourProfile {
  @media only screen and (max-width: 765px) {
    .borderOnBigScreenOnly {
      border: none !important;
    }
  }

  input[type='text'].cwp:invalid:focus,
  input[type='password'].cwp:invalid:focus,
  input[type='secret'].cwp:invalid:focus,
  input[type='email'].cwp:invalid:focus,
  input[type='tel'].cwp:invalid:focus,
  input[type='date'].cwp:invalid:focus {
    background-position: right;
    background-image: url('../../../assets/icons/alert.svg');
  }

  .toolTipWrapper {
    margin-top: -25px;
    margin-left: 235px;
  }

  label.cwp {
    font-size: 12px;
    margin-left: 0;
    margin-bottom: 0;
  }

  label.cwp.floating {
    position: absolute;
    font-size: 1em;
    margin-left: 0px;
  }

  label.cwp.floating + input::placeholder {
    color: transparent;
  }

  label + select {
    background-color: #fff;
  }

  label.cwp.floating.selectBox {
    margin-left: -24000px;
  }

  .duet-date__dialog.is-active {
    background-color: #fff;
  }
}

.formSectionFourhundred {
  height: 400px;
  min-height: 400px;
}

.addressVerificationBox,
.addressVerificationBoxSelected {
  background-color: $article_background_color;
  display: block;
  text-align: left;
  min-width: 300px;

  background-image: url(../../../assets/icons/radio-button-off.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 24px;
  padding: 30px 30px 30px 80px;
  cursor: pointer;
}

.addressVerificationBoxSelected {
  background-image: url(../../../assets/icons/radio-button-on.svg);
}
