.changePlan div.wrapper {
  max-width: 400px;
  width: 100%;
  margin: auto;
  text-align: left;
  min-height: 130px;
}

.changePlan .wrapperFloatLeft {
  float: left;
}
.changePlan .wrapperFloatRight {
  float: right;
}

.alignChangeServicesTextBlockLeft {
  display: block;
  text-align: left !important;
}

@media only screen and (max-width: 820px) {
  .changePlan .wrapperFloatLeft,
  .changePlan .wrapperFloatRight {
    float: none;
    margin: auto;
  }
}
