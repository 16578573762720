@import '../colorVariables.scss';

.center {
  margin: auto;
  width: auto;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 26px !important;
  color: $primary_color;
  cursor: pointer;
  font-size: 15px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal !important;

  .input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    border-radius: 1px;
    background-color: $background_color;
    border: 1px solid $primary_color;
  }

  :hover input ~ .checkmark,
  input:checked ~ .checkmark {
    background-color: $background_color;
    border: 1px solid $primary_color;
  }

  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: 1px solid $primary_color;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(46deg);
    -ms-transform: rotate(46deg);
    transform: rotate(46deg);
  }
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
