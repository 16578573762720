@import '../../reusable_cmp_lib/colorVariables.scss';
.model {
  background-color: #001e50;
  height: 43px;
}
.centeralign {
  margin-top: -32px;
}
img:-moz-broken {
  opacity: 0;
}
.modelnmtext {
  display: none;
}

#footer {
  width: 100%;
  padding: 0;
  list-style: none;
}
#footer {
  float: left;
}
#footer a {
  padding: 0px 5px;
  text-decoration: none;
  color: white;
  cursor: pointer;
}
.main-footer {
  color: white;
  background-color: black;
  position: relative;
  padding-top: 1.2em;
  width: 100%;
  font-size: 14px;
  min-height: 120px;
}

.img-responsivelogo {
  margin-left: -11px;
}
.img-responsive {
  padding-bottom: 28px;
  margin-top: 19px;
  padding-right: 16px;
}

.img-responsive-logo {
  padding-bottom: 8px;
  width: 98px;
  min-height: 80px;
}

.col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.container-fluid {
  margin-bottom: 5%;
}

.nav-link {
  color: white;
  margin-top: -8px;
  padding-right: 0rem;
  padding-left: 0.5rem;
}

.nav-link.active {
  color: #ffffff !important;
}

@media only screen and (max-width: 769px) {
  .righttalign {
    text-align: right;
    margin-top: -44px;
  }

  .leftalign {
    margin-top: -68px !important;
    text-align: left;
  }

  .modelYr {
    font-size: 24px;
    color: white;
    font-size: 16px;
    letter-spacing: 1px;
    margin-left: 10px;
  }

  .modelnm {
    font-size: 24px;
    color: white;
    font-size: 16px;
    letter-spacing: 1px;
    margin-left: 3px;
  }
  .add {
    font-size: 15px;
    color: white;
    padding-right: 11px;
    letter-spacing: 1px;
  }
}

@media only screen and (min-width: 960px) {
  .righttalign {
    text-align: right;
    margin-top: -44px;
    margin-right: 3rem;
  }

  .leftalign {
    margin-top: -68px !important;
    margin-left: 55px !important;
    text-align: left;
  }

  .modelYr {
    font-size: 24px;
    color: white;
    font-size: 23px;
    letter-spacing: 1px;
    margin-left: 10px;
  }

  .modelnm {
    font-size: 24px;
    color: white;
    font-size: 23px;
    letter-spacing: 1px;
    margin-left: 3px;
  }

  .add {
    font-size: 15px;
    color: white;
    padding-right: 11px;
    letter-spacing: 1px;
  }
}

.cwp-header-post-login hr {
  border: 1px solid #ffffff;
  margin-top: -44px !important;
}

.cwp-header-post-login {
  width: 99.9vw;
  margin-left: -9px;
  height: 84px;
}

.cwp-header-post-login #vwlogo-post-login {
  background-color: #001e50;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  background-image: url(../../../assets/images/logo.png);
  background-size: 48px;
  background-position: center;
  background-repeat: no-repeat;
  width: 68px;
  height: 68px;
  position: absolute;
  right: 33%;
  top: -36px;
}

span.add:focus {
  outline: 3px solid $focus_outline_color;
}
