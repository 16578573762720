body,
div {
  font-family: 'VWText';
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-family: 'VWHead';
    font-weight: bold;
  }
}
