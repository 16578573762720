@import '../../reusable_cmp_lib/colorVariables.scss';

.unEnrollUbiDataShare {
  .pageHeight {
    min-height: 65vh;
  }

  .editHeader {
    font-family: VWHead;
    font-size: 32px;
    font-weight: 300;
  }

  .driveViewHeader {
    font-family: VWHead;
    font-size: 24px;
    font-weight: 300;
  }

  .consentText {
    font-family: VWText;
    font-size: 14px;
    color: $text_primary_color;
    width: 300px;
  }

  .driveViewConsent {
    font-family: VWText;
    font-size: 14px;
    width: 300px;
  }

  .driveViewConsentText {
    color: $text_primary_color;
  }

  .saveChangesBtn {
    font-family: VWText;
    font-size: 18px;
    width: 175px;
  }

  .tosTypesText {
    font-size: 16px;
    font-family: VWText;
    color: $carnet_root_color;
  }

  .areYouSureHeader {
    font-family: VWHead;
    font-size: 36px;
    font-weight: bold;
  }

  .byEnrollingBody {
    font-family: VWText;
    font-size: 20px;
  }

  .modalBtn {
    width: 361px;
    margin-right: 11%;
  }

  .disableDataShare {
    opacity: 0.5;
    pointer-events: none;
  }

  .driveViewNote {
    font-family: VWText;
    font-size: 12px;
    color: $text_label_dark_color;
    max-width: 720px;
    margin-top: 2rem;
  }
}
