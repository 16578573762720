@import '../../../reusable_cmp_lib/colorVariables.scss';

section#articleUbiPausedMsg {
  background-color: $modal_background_color;
  color: $modal_text_color;
}

section#articleUbiPausedMsg article {
  background-image: url('../../../../assets/images/info-white.svg');
  background-repeat: no-repeat;
  background-position: 1rem 1rem;
  padding-left: 4.5rem;
}
