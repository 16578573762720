@import '../../reusable_cmp_lib/colorVariables.scss';
.vhr-header {
  text-align: center;
}

.vhr-header-title1 {
  font-size: 32px;
  font-family: VWHeadLight;
  color: $text_primary_color;
}

.vhr-header-title2 {
  font-size: 32px;
  font-family: VWTextBold;
  margin-left: 6px;
  color: $text_primary_color;
}

.refresh-div {
  font-size: 16px;
  text-align: left;
  margin-left: 6%;
  margin-top: 2px;
  font-family: VWTextBold;
  position: absolute;
}

.refresh-text {
  color: $primary_color;
  background-color: $background_color;
  border: 0px;
}

.vhr-background {
  background-color: $background_light_color;
}

ul.cwp-inline {
  list-style-type: none;
  list-style: none;
  width: 100%;
  display: inline-block;
  display: table;
  text-align: center;
  margin: 0px;
  border: 0px;
  padding: 0px;
}

ul.cwp-inline li {
  float: left;
  text-align: center;
}

.view-720 li.left-vhr {
  width: 40%;
}

.left-vhr {
  width: 26%;
}

.right-vhr {
  width: 32%;
  margin-right: 2%;
  margin-left: 2%;
}

.line {
  width: 1px;
  height: 417px;
  object-fit: contain;
  opacity: 0.5;
  border: solid 1px $text_label_dark_color;
  float: left;
}

#content {
  max-width: 100%;
}

img {
  top: -1225px !important;
}

ul.cwp-inline {
  list-style-type: none;
  list-style: none;
  width: 100%;
  display: inline-block;
  display: table;
  text-align: center;
  margin: 0;
  border: 0;
  padding: 0;
}

.left-vhr {
  width: 26%;
}

ul.cwp-inline li {
  float: left;
  text-align: center;
}

.right-vhr {
  width: 32%;
  margin-right: 2%;
  margin-left: 2%;
}

div.cwp-page-header {
  text-align: center;
  background-color: $background_light_color;
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 5px;
  padding-bottom: 10px;
  background-repeat: no-repeat;
  background-position: center 20px;
  border-radius: 10px;
  background-size: 42px;
}

.view-960 div.cwp-page-header {
  background-size: 72px;
  padding-top: 100px;
}

.view-lt480 div.cwp-page-header {
  background-size: 11vw;
  padding-top: 70px;
}

span.cwp-page-header {
  font-size: 30px;
  line-height: 60px !important;
}

.view-lt480 span.cwp-page-header {
  font-size: 28px;
  line-height: 50px !important;
  font-weight: bold;
}

span.cwp-page-slogan {
  font-family: inherit;
  line-height: 40px;
  font-weight: normal;
  font-size: small;
}

.view-lt480 span.cwp-page-slogan {
  font-size: 2.5vw;
  line-height: 2.5vw;
}

ul.cwp-inline {
  list-style-type: none;
  list-style: none;
  width: 100%;
  display: inline-block;
  display: table;
  text-align: center;
  margin: 0px;
  border: 0px;
  padding: 0px;
}

ul.cwp-inline li {
  float: left;
  text-align: center;
}

.view-lt720 ul.cwp-inline li {
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0px;
  padding-bottom: 10px;
}

// hr.cwp {
//   background-color: #d0d2d2;
// }

div.vehicle-details {
  padding: 2%;
  font-size: 13px;
  text-align: left;
  color: $primary_color;

  .width217 {
    width: 217px;
  }
}

.cwp-text-gray {
  color: $text_label_color;
}

.view-lt960 .vhr-footer li {
  background-color: #e3ebec;
  text-transform: uppercase;
}

.remaining-vhr-count {
  font-weight: bold;
  font-size: 90px;
  line-height: 70px;
}

.view-lt960 .remaining-vhr-count {
  font-size: 146px;
  line-height: 100px;
}

.view-lt480 .remaining-vhr-count {
  font-size: 31vw;
  line-height: 20vw;
}

li.vhr-counter {
  width: 80px;
  float: left;
  text-align: left;
}

li.vhr-message {
  width: 180px;
  float: left;
  text-align: left;
}

.view-lt960 li.vhr-counter {
  width: 100%;
  float: left;
  text-align: center;
}

.view-lt960 li.vhr-message {
  width: 100%;
  float: left;
  text-align: center;
}

table.cwp_txt_chk {
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.view-720 li.left-vhr {
  width: 40%;
}

.view-lt960 li.monthly-reports {
  background-color: $background_light_color;
}

.view-960 .border-left {
  border-left: 1px solid #d0d2d2;
}

.view-960 .border-right {
  border-right: 1px solid #d0d2d2;
}

.monthly-vhr-container {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.aui .control-group {
  margin-bottom: 0px;
}

.cwp-column {
  text-align: left;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.cwp-column h3 {
  margin-top: 0px;
}

.cwp-column-full-size {
  text-align: left;
  width: 100%;
  padding: 2%;
  font-family: VWText;
  font-size: 12px;
  line-height: 1.33;
  color: $primary_color;
}

.vhr-details {
  font-family: VWHead;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: $text_primary_color;
}

.refresh-img {
  width: 20px;
  height: 20px;
}

.vhrRow {
  background-position: right 9px;
  background-repeat: no-repeat;
  background-size: 22px;
  height: 32px;
}

.vhrRowDetails {
  background-position: left 4px;
  background-size: 16px;
  padding-bottom: 5px;
  background-repeat: no-repeat;
  border-bottom: 1px solid $text_label_dark_color;
  margin-top: 10px;
  &.noBorder {
    border-bottom: none;
  }
}

.vhr-status-title {
  background-repeat: no-repeat;
  background-position: left;
  display: table;
  padding-left: 38px;
  line-height: 40px;
  font-family: VWHead;
  font-size: 20px;
  font-weight: bold;
  color: $text_primary_color;
}

.vhrhistory {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  height: 35px;
}

.view-lt720 .vhr-status-title {
  padding-left: 30px;
  line-height: 25px;
  font-size: 15px;
}

.fontSize {
  font-size: 13px;
  font-family: VWText;
  font-weight: normal;
}

.odo {
  font-size: 13px;
}

.priorityCodeA {
  background-image: url('../../../assets/vehicleHealth/red.png');
  background-size: 18px 18px !important;
}

.priorityCodeB {
  background-image: url('../../../assets/vehicleHealth/yellow.png');
  background-size: 18px 18px !important;
}

.priorityCodeC {
  background-image: url('../../../assets/vehicleHealth/blue.png');
  background-size: 18px 18px !important;
}

.priorityCodeD {
  background-image: url('../../../assets/vehicleHealth/green.png');
  background-size: 18px 18px !important;
}

table.vhr-legend {
  height: 70px;
  text-align: left;
  margin-left: 75px;
}

table.vhr-legend div.legend-text {
  -ms-transform: rotate(270deg); /* IE 9 */
  -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
  transform: rotate(270deg);
  font-weight: bold;
}

table.vhr-legend td.legend-icon {
  min-height: 50px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 20px;
  width: 24%;
  vertical-align: top;
  font-size: 12px;
  margin-left: 25%;
  font-family: VWText;
  font-size: 12px;
  line-height: 13px;
}

div.vhr-preferrences {
  width: calc(60% - 10px);
  padding: 5px;
  float: left;
}

.view-lt720 div.vhr-preferrences {
  width: calc(100% - 10px);
}

div.vhr-preferrence-desclaimer {
  width: calc(40% - 20px);
  float: right;
  padding: 10px;
  padding-top: 100px;
  text-align: left;
}

.view-lt720 div.vhr-preferrence-desclaimer {
  width: calc(100% - 20px);
  float: left;
  padding-top: 10px;
}

table.table .colMonthly,
table.table .colAlert {
  text-align: center;
}

table.table .colAlert {
  display: none;
}

span.cwp_disclaimer_indicator {
  vertical-align: super;
  font-size: 80%;
}

.cwp-arrow-right {
  padding-right: 20px;
  background-size: auto 20px;
}

table.iceDisabledDialog {
  position: absolute;
  z-index: 1001;
  width: 100%;
  height: inherit;
}

.iceDisabledDialog div {
  border: 2px solid #444;
  width: 60%;
  margin: auto;
  padding: 5px;
}

.view-lt960 .iceDisabledDialog div {
  width: 80%;
}

.view-lt480 .tileVehicleHealth .iceDisabledDialog {
  display: none;
}

.view-gt480 .tileSpeedAlert .iceDisabledDialog {
  display: none;
}

.emails-left {
  float: left;
  margin-left: 25px;
}

.phones-right {
  float: right;
  margin-right: 25px;
}

.service-btn-disable {
  width: 160px;
  background-color: #859a9f;
  color: $background_light_color;
  font-size: 13px;
  height: 31px;
  border-radius: 3px;
  text-align: center;
  line-height: 28px;
}

.priorityCode-legend {
  font-family: VWText;
  font-size: 14px;
  margin-left: 35px;
  margin-top: 32px;
  color: $text_primary_color;
}

.legend-text {
  font-size: 14px;
  color: $text_label_dark_color;
  float: left;
  margin-left: 20px;
}

.modal-text-enable {
  color: $carnet_root_color;
  font-weight: bold;
  text-align: right;
  font-size: 14px;
  float: right;
  margin-right: 40px;
  border-bottom: 1.5px solid $carnet_root_color;
  padding-bottom: 6px;
  cursor: pointer;
}

.modal-text-disable {
  width: 30%;
  text-align: center;
  color: #ccc;
  font-weight: bold;
  margin-left: 66px;
}

.vhr-home-div {
  background-color: $background_light_color;
  padding: 30px;
  // border-top: 3px solid #eee;
}

.monthly-report {
  color: $text_primary_color;
}

.save-btn {
  width: 217px;
  background-color: $primary_color;
  color: $background_light_color;
  font-size: 14px;
  height: 44px;
  border-radius: 24px;
  border: 1px solid $primary_color;
  box-shadow: 2px 1px 2px #ccc;
}

.vhr-manage-contacts-btn {
  width: 217px;
  background-color: $background_light_color;
  color: $primary_color;
  font-size: 14px;
  height: 44px;
  border-radius: 24px;
  border: 2px solid $primary_color;
  box-shadow: 2px 1px 2px #ccc;
  margin-bottom: 20px;
}

.vhr-viewdetails-btn {
  width: 217px;
  background-color: $background_light_color;
  color: $primary_color;
  font-size: 16px;
  height: 44px;
  border-radius: 24px !important;
  border: 1px solid $primary_color !important;
}

.sxmdetails-btn {
  width: 158px;
  background-color: $primary_color !important;
  color: $background_light_color !important;
  font-size: 16px;
  height: 44px;
  border-radius: 24px !important;
  border: 1px solid $primary_color !important;
}

.save-btn-disable {
  width: 217px;
  background-color: #dfe4e8;
  color: $text_label_dark_color;
  font-size: 14px;
  height: 44px;
  border-radius: 24px;
  border: 1px solid #dfe4e8;
}

.arrow-style {
  width: 22px;
  height: 23px;
  object-fit: contain;
}

.cwp-blue {
  font-size: 12px;
  color: #001e50;
  cursor: pointer;
}

.vhr-history-table {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

.vhr-history-div {
  margin-bottom: 0px;
  text-align: center;
}

.vhr-history-title {
  font-size: 20px;
  color: $text_primary_color;
}

.history-data-table {
  border: 1px solid #ccc;
  width: 100%;
  margin: 0 auto;
  background: $background_light_color;
  margin-top: 20px;
  font-size: 14px;
}

.history-data-tr {
  border-bottom: 1px solid #ccc;
  height: 36px;
  color: $text_label_dark_color;
  font-size: 14px;
  font-family: VWText;
}

.history-data-td {
  width: 30%;
  text-align: center;
  color: $text_primary_color;
}

.service-key {
  color: $carnet_root_color;
  font-weight: bold;
  cursor: pointer;
}

.history-modal {
  max-width: 650px;
  width: 80%;
  margin: 10px auto;
}

.category-desc {
  color: $text_label_dark_color;
  font-weight: bold;
  font-size: 16px;
  font-family: VWText;
}

.subcategory-desc {
  font-size: 16px;
  color: $text_label_dark_color;
  margin-left: 25px;
}
.diagnostic-desc {
  font-size: 12px;
  color: $text_label_dark_color;
}

/* SirisXM styles */
.sirisXM-logo {
  width: 195px;
  height: 37px;
}
.sxm-logo-align {
  text-align: center;
}
.sxm-btn-align {
  text-align: center;
}

.sirisXM-header {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
  font-family: VWTextBold;
  margin-bottom: 12px;
}

.sxm-segmentMsg {
  font-size: 17px;
  margin-left: 40px;
  margin-right: 40px;
}

.audioSubDiv {
  min-width: 330px;
  max-width: 364px;
  width: calc(100% - 20px);
  min-height: 90px;
  border-radius: 5px;
  background-color: $article_background_color;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  margin: 10px auto 30px auto;
  float: left;
  display: block;
}

.view-960 .infotainmentSub {
  margin-left: calc(50% - 560px);
  margin-right: calc(50% - 560px);
}
.audio-text-td {
  float: left;
  font-size: 14px;
  color: #666666;
  margin-top: 20px;
  margin-left: 15px;
}

.audio-img-td {
  float: right;
  font-size: 14px;
  color: #37b26d;
  margin-left: 115px;
  margin-top: 20px;
  margin-right: 24px;
}

.audio-sub-td {
  float: left;
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
}

.segment-msg {
  font-size: 16px;
  margin-left: 40px;
  margin-right: 40px;
}

.status-activetrial {
  width: 95px;
  height: 30px;
}

.status-active {
  width: 65px;
  height: 30px;
}

.status-inactive {
  width: 69px;
  height: 30px;
}

.tb-margin {
  margin: 0px 10px 0px 10px;
}

.sxm-status {
  background-color: $primary_color;
  height: 90px;
  width: 100%;
}

.sxm-stausheader {
  font-size: 20px;
  text-align: center;
  color: $background_light_color;
}

/* Latest CSS */
.priorityCode-red {
  background-image: url('../../../assets/vehicleHealth/red.png');
  background-size: 16px 16px !important;
}

.priorityCode-yellow {
  background-image: url('../../../assets/vehicleHealth/yellow.png');
  background-size: 16px 16px !important;
}

.priorityCode-green {
  background-image: url('../../../assets/vehicleHealth/green.png');
  background-size: 16px 16px !important;
}
.link {
  font-size: 14px;
  font-weight: bold;
  color: $carnet_root_color;
  border-bottom: 1px solid $carnet_root_color;
  padding-bottom: 6px;
  float: left;
  margin-top: 10px;
}

.imp-notice {
  font-size: 12px;
  color: $primary_color;
  margin-top: 15px;
  margin-bottom: 21px;
  font-family: VWText;
  font-weight: normal;
}

.modal-header {
  font-family: VWHead;
  font-size: 20px;
  color: $text_primary_color;
  font-weight: bold;
  border-bottom: 0px;
  margin-top: 5px;
  margin-bottom: -19px;
}

.modal-message {
  font-size: 12px;
  color: $primary_color;
}

.modal-category-message {
  font-size: 16px;
  color: $text_label_dark_color;
  font-weight: bold;
}

.link-color {
  color: $carnet_root_color;
  cursor: pointer;
}
.arrow {
  width: 9px;
  height: 8px;
}

.add {
  height: 14px;
}

.leftCol {
  margin-left: 16%;
}

.middleCol {
  margin-left: 4%;
  margin-right: 8%;
}

.rightCol {
  margin-left: 6%;
  margin-right: 2%;
}

.capability-status {
  font-size: 17px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 300px;
  font-weight: bold;
}

.vhr-title {
  text-align: center;
  margin-bottom: 34%;
  margin-top: 20px;
}

.vhr-refresh-failed {
  font-size: 15px;
  font-family: VWText;
  color: $remove_item_color;
  margin-left: 10%;
  position: absolute;
  margin-top: 31px;
}

.vhr-reportcnf-modal {
  background-color: $modal_background_color;
  border-top: 0px;
}

.vhr-reportcnf-header {
  font-family: VWHead;
  font-size: 22px;
  font-weight: bold;
  color: $background_color;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
}
.vhr-reportcnf-text {
  font-family: VWText;
  font-size: 20px;
  margin-left: 30px;
  color: $background_color;
}

.vhr-preferred-dealer {
  font-family: VWText;
  font-size: 13px;
  font-weight: normal;
}

#vhr-hidecontainer {
  overflow: hidden;
  position: relative;
}
#vhr-hide-prefsuccess {
  -webkit-animation: cssAnimation 5s forwards;
  animation: cssAnimation 5s forwards;
}
@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.vhr-prioritycode-img {
  background-size: 24px 24px !important;
}

.vhr-details-img {
  background-size: 14px 14px !important;
}

.vhr-schedule-service {
  font-family: VWText;
  font-size: 14px;
  color: $primary_color;
  font-weight: normal;
}

.vhr-modal-exit {
  background-image: url('../../../assets/tripStats/close.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  float: right;
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-top: 15px;
}

div.service-key:focus,
div.vhr-modal-exit:focus,
a.link-color:focus,
div.link-color:focus,
button.gotit-btn:focus {
  outline: 3px solid $focus_outline_color;
}

@media (max-width: 767px) {
  .line {
    display: none;
  }
  .legend-text {
    display: none;
  }
  .refresh-div {
    font-size: 12px;
    position: relative;
  }
  .vhr-refresh-failed {
    margin-top: 0px;
    margin-left: 26%;
  }
  .vhr-status-title {
    font-size: 15px;
  }
  .category-desc {
    font-size: 13px;
  }

  .table.vhr-legend {
    margin-left: 5px;
  }

  .vhr-header {
    margin-top: 15px;
  }
  .vhr-header-title1 {
    font-size: 26px;
  }
  .vhr-header-title2 {
    font-size: 26px;
  }

  .priorityCode-legend {
    margin-left: 18px;
  }

  .vhr-viewdetails-btn {
    width: 70px;
    background-color: $background_light_color;
    color: $primary_color;
    font-size: 9px;
    height: 18px;
    border-radius: 24px !important;
    border: 1px solid $primary_color !important;
  }

  .sxm-stausheader {
    font-size: 10px;
    margin-left: -70px;
  }
  .sirisXM-logo {
    width: 80px;
    height: 20px;
  }
  .sxm-logo-align {
    text-align: left;
    margin-left: -20px;
  }
  .sxm-status {
    height: 70px;
  }
  .sxm-btn-align {
    text-align: right;
  }
  .vhr-details {
    font-size: 16px;
    margin-top: 15px;
  }
  .monthly-report {
    font-size: 16px;
  }

  .bottom-row {
    padding-left: 30px;
    margin-bottom: 15px;
    margin-top: 15px;
    border-bottom: 2px solid $text_label_dark_color;
  }
}

.service-modal-text {
  background-color: $modal_background_color;
  font-size: 20px;
  color: $background_color;
  text-align: center;
}

.service-modal-urltext {
  background-color: $modal_background_color;
  font-size: 20px;
  color: #00e6e6;
  text-align: center;
}

.pref-success {
  font-size: 13px;
  color: $text_success_color;
  margin-bottom: 10px;
}

.vhr .modal-content {
  background-color: $modal_background_color;
  text-align: center;
}

.vhr .modal-footer {
  border-top: 0px;
  margin-bottom: 10px;
}

.gotit-btn {
  width: 340px;
  height: 42px;
  border-radius: 24px;
  background-color: $background_color;
  font-size: 16px;
  color: $primary_color;
}

.modalbody-txt {
  font-size: 20px;
  color: $background_color;
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.modalheader-txt {
  font-size: 29px;
  font-weight: bold;
  color: $background_color;
  justify-content: center;
}

.checkbox-margin {
  margin-left: 20px;
}

.categories-margin {
  margin-top: 10px;
}

.vehicledetails-margin {
  margin: 0px;
}

.priorityCodeLegendA {
  background-image: url('../../../assets/vehicleHealth/red.png');
  background-size: 14px 14px !important;
}

.priorityCodeLegendB {
  background-image: url('../../../assets/vehicleHealth/yellow.png');
  background-size: 14px 14px !important;
}

.priorityCodeLegendC {
  background-image: url('../../../assets/vehicleHealth/blue.png');
  background-size: 14px 14px !important;
}

.priorityCodeLegendD {
  background-image: url('../../../assets/vehicleHealth/green.png');
  background-size: 14px 14px !important;
}

.historyCategoryDesc {
  color: $text_label_dark_color;
  font-weight: normal;
  font-size: 12px;
}

.historySubcategoryDesc {
  font-size: 12px;
  color: $text_label_dark_color;
  margin-left: 25px;
}

.vhr-form-check-input {
  display: none;
}

.vin-details {
  font-size: 12px;
}

.vhr-loader {
  margin-bottom: 150px;
}

.back-vhr {
  margin-top: 20px;
  cursor: pointer;
}
