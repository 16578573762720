@import '../../reusable_cmp_lib/colorVariables.scss';
.logInOut {
  float: right;
  margin: 2px 42px 0 0;
  padding: 0;
  width: auto;
  min-width: 60px;
  text-align: left;

  cursor: pointer;

  span {
    color: $carnet_root_color;
    font-family: 'VWTextBold';
    font-size: 1rem;
    opacity: 1;
  }

  span:hover {
    color: $newtheme_marine_dark;
  }

  span.disabled {
    opacity: 0.3;
    cursor: default;
  }

  &.inverted {
    span {
      color: $button_primary_text;
      &:hover {
        color: #efefef;
      }
    }
  }
}
