@import '../../reusable_cmp_lib/colorVariables.scss';

.gmap-pin-halo {
  padding: 10px 10px;
}

.tooltip-newstyle {
  position: absolute;
  left: 4px;
  top: -60px;
  max-width: 18em;
  padding: 5px 8px;
  background: $modal_background_color;
  border-radius: 0px;
  color: $button_primary_text;
  z-index: 9;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: $modal_background_color;
    border-bottom: 0;
    border-left: 0;
    margin-left: -10px;
    margin-bottom: -20px;
  }
  .tooltip-text {
    overflow-wrap: normal;
    font-family: 'VWText';
    font-size: 8pt;
    font-weight: bold;
  }
}

.gmap-pin-circle {
  background-color: $remove_item_color;
  width: 24px;
  height: 24px;
  border-radius: 28px;
  text-align: center;
  vertical-align: center;

  .gmap-pin-circleText {
    font-weight: bold;
    color: $button_primary_text;
    font-size: 12px;
  }
}
