.carnet-form {
  width: 250px;

  input[type='file'] {
    display: none;
  }

  .file-upload {
    background-color: #00b1eb;
    color: white;
    font-weight: bold;
    border-radius: 3px;
    padding: 4px;
    min-width: 80px;

    ::hover {
      cursor: pointer;
      background-color: #00b1eb;
    }
  }

  .form-group {
    width: 250px;

    .form-text {
      width: 250px;
      height: 240px;
    }

    .form-input-label {
      font-size: 8pt;
      color: #404040;
    }
    .form-input {
      color: inherit;
      border-top: none;
      border-left: none;
      border-right: none;
      font-size: 12pt;
      ::placeholder {
        color: silver;
        font-weight: lighter;
        font-size: 12pt;
      }
    }
  }
}

@import '../../../../node_modules/bootstrap/scss/bootstrap';
