@import '../../reusable_cmp_lib/colorVariables.scss';
.manageAlertContactsModal {
  .action-link {
    cursor: pointer;
  }

  .macm-header-title {
    font-family: 'VWHead';
    font-size: 20px;
    object-fit: contain;
    font-weight: bold;
    line-height: 1.2;
    color: black;
  }

  #macm-body-instructions {
    object-fit: contain;
    font-family: 'VWText';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $carnet_root_color;
  }

  .records-table-rows {
    border-bottom: 1px solid $hyperfaint_underline_color;
    padding: 8px 0px;
  }

  .macm-record-column-lbl {
    font-family: 'VWHead';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $popups_pearl_color;
  }

  .add-record-suggestion {
    font-family: 'VWHead';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $faintest_suggestion_color;
  }

  .existing-record-text {
    font-family: 'VWHead';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    padding: 10px 0px;
    letter-spacing: normal;
    color: $carnet_root_color;
  }

  .add-contact-field-link {
    font-family: 'VWText';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $newtheme_accent;
  }

  .macm-delete-button {
    color: red;
    font-weight: bold;
  }
}
