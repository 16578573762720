.message-center-css-insulator {
  .header {
    font-size: 18px;
    display: inline-block;
    font-family: 'VWTextBold';
    margin-left: 4px;
    cursor: pointer;
  }
  .createdTime {
    font-size: 14px;
    display: inline-block;
    color: #00b1eb;
  }

  .subHeader {
    font-size: 16px;
    display: inline-block;
    font-family: 'VWTextBold';
    color: #001e50;
  }

  .richText {
    font-size: 15px;
    color: #6a767d;
  }

  .hrline {
    margin-top: 19px;
    margin-bottom: 10px;
    border-top: 1px solid #ced7d9;
  }

  .message {
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    padding-top: 20px;
    margin-bottom: 30px;
  }

  .message-header {
    font-size: 2rem;
    text-align: center;
    margin: 0 auto;
    font-family: 'VWTextBold';
    font-weight: 400;
    margin-top: 60px;
    h1 {
      font-family: 'VWTextBold';
    }
  }

  .search {
    width: 300px;
    border-radius: 1px;
    border: white;
    height: 38px;
    border: 1px solid #eee;
  }
  .search-info {
    display: inline;
    width: 24px;
    height: 24px;
    margin-left: -30px;
    border: 0px;
    background-image: url(../../../assets/images/search.png);
    background-size: 20px;
    background-position-y: 2px;
    background-position-x: 1px;
    background-repeat: no-repeat;
    background-color: white;
    color: white;
    vertical-align: text-top;
    margin-bottom: 50px;
  }

  .ms-input {
    width: 280px;
    height: 42px;
  }
  .mscenter {
    list-style-type: none;
    padding-right: 17px;
  }

  .table-ms {
    width: 92%;
    margin: 3rem auto auto auto;
    display: -ms-flexbox;
    display: flex;
  }

  .categories {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding: 0;
    margin: 0;
    li {
      text-align: center;
      -ms-flex: 1;
      flex: 1;
    }
  }

  .header-detail {
    font-family: 'VWTextBold';
    text-align: left;
    font-size: 24px;
    display: block;
    margin: 0;
    padding: 10px 0;
  }
  .messageAll {
    font-size: 15px;
    display: inline;
    font-family: 'VWTextBold';
    color: #001e50;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .activeall {
    display: inline;
    color: #007acc;
    cursor: pointer;
    font-family: 'VWTextBold';
    font-size: 15px;
    padding: 0;
    margin: 0;
    text-decoration: underline !important;
  }

  .messageAll:hover {
    color: #007acc;
  }

  .delete {
    float: right;
    cursor: pointer;
    color: #001e50;
    font-family: 'VWTextBold';
    &.rightPadding20 {
      margin-right: 20px;
    }
  }

  #none-found-indicator {
    float: left;
    margin-left: 40%;
    color: red;
    font-family: 'VWTextBold';
    font-weight: bold;
  }

  .showmore {
    cursor: pointer;
    color: #001e50;
    font-family: 'VWTextBold';
  }
}

.message-center-modal {
  .btn-secondary {
    color: #001e50 !important;
    background-color: #fff !important;
    border: 2px solid #001e50 !important;
    border-radius: 24px !important;
    width: 125px;
    height: 44px;
  }

  .btn-primary {
    color: #fff !important;
    background-color: #001e50 !important;
    border-color: #001e50 !important;
    border-radius: 24px !important;
    width: 125px;
    height: 44px;
  }
}

@media only screen and (max-width: 960px) {
  .message-center-css-insulator {
    overflow-x: auto;
    .message {
      height: auto;
      width: 100%;
      margin-left: 0px;
      background-color: #fff;
      border: 1px solid #f2eeee;
      padding: 29px;
      margin-bottom: 5rem;
    }

    .messageAll {
      font-size: 16px;
      display: inline;
      font-family: 'VWTextBold';
      color: #001e50;
      cursor: pointer;
    }
    .activeall {
      display: inline;
      color: #00b1eb;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
