@import '../../reusable_cmp_lib/colorVariables.scss';
.rts-home {
  margin-top: 15px;
  max-width: 1140px;
  margin-right: 10%;
  margin-left: 12%;
}

.rts-header {
  text-align: center;
  margin-bottom: 15px;
  font-size: 32px;
  color: $text_primary_color;
}

.rts-title1 {
  font-family: VWHeadLight;
}

.rts-title2 {
  font-family: VWTextBold;
  margin-left: 6px;
}

.rts-desc {
  font-family: VWText;
  font-size: 16px;
  text-align: center;
  color: $text_label_dark_color;
  margin-bottom: 15px;
}
.rts-driveview-features {
  background-color: $article_background_color;
  margin-top: 10px;
  height: 225px;
}
.rts-hide {
  font-family: VWText;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  cursor: pointer;
}

.rts-get-your-driveview {
  font-family: VWHead;
  font-size: 24px;
  font-weight: bold;
  color: $text_primary_color;
  margin-bottom: 32px;
  margin-left: 32px;
}

.rts-driveview {
  font-family: VWHead;
  font-size: 16px;
  font-weight: bold;
  color: $text_primary_color;
  margin-left: 10px;
}

.driview-enroll-btn {
  width: 217px;
  background-color: $primary_color;
  color: $background_light_color;
  font-size: 14px;
  height: 44px;
  border-radius: 24px;
  border: 1px solid $primary_color;
  box-shadow: 2px 1px 2px #ccc;
}

.driview-feature-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.driview-btn {
  margin-left: 32px;
}

.feature-icon {
  margin-left: 32px;
}

.up-icon {
  width: 14px;
  height: 14px;
  margin-left: 9px;
  margin-right: 10px;
}

.rts-line {
  border-bottom: 2px solid $primary_color;
  //margin-top: 32px;
  //margin-bottom: 39px;
}

.rts-card {
  border-radius: 5px;
  border: solid 0.5px $primary_color;
  background-color: $background_color;
}

.rts-arrowicon {
  width: 24px;
  height: 23px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.rts-nav {
  font-family: VWText;
  font-size: 14px;
  color: $primary_color;
  cursor: pointer;
}
.rts-previous-next {
  padding: 42%;
}

.rts-toggole {
  margin-left: 48%;
  margin-right: 48%;
}

.rts-history-table {
  width: 100%;
  margin: 20px auto 40px;
}
.rts-history-div {
  margin-bottom: 0;
  text-align: center;
}
.rts-history-title {
  font-size: 24px;
  color: $text_primary_color;
  font-family: VWHead;
  text-align: center;
  margin-top: 20px;
}
.rts-delete-history {
  font-family: VWText;
  font-size: 16px;
  font-weight: bold;
  color: $remove_item_color;
  float: right;
  cursor: pointer;
}
.rts-driveview-collapse {
  background-color: $article_background_color;
  margin-top: 32px;
  height: 150px;
  margin-bottom: 55px;
  margin-left: 10%;
  margin-right: 10%;
}

.rts-show {
  font-family: VWText;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  cursor: pointer;
}
.down-icon {
  width: 14px;
  height: 14px;
  margin-left: 9px;
  margin-right: 10px;
}

.rts-tripid {
  font-family: VWText;
  font-size: 16px;
  font-weight: bold;
  color: $carnet_root_color;
}

.rts-history-table {
  font-family: VWText;
  font-size: 14px;
}
.rts-modal-time {
  font-family: VWHead;
  font-size: 22px;
  font-weight: bold;
  color: $text_primary_color;
  margin-top: 30px;
  margin-bottom: 10px;
}

.rts-modal-date {
  font-family: VWText;
  font-size: 14px;
  font-weight: bold;
  color: $text_primary_color;
  margin-bottom: 12px;
}
.rts-modal-distance {
  font-family: VWText;
  font-size: 14px;
  margin-bottom: 52px;
  color: $text_primary_color;
}

.rts-modal-average {
  font-family: VWText;
  font-size: 12px;
  font-weight: bold;
  color: $primary_color;
  margin-right: 6.7%;
}

.rts-modal-mpg {
  font-family: VWText;
  font-size: 24px;
  font-weight: bold;
  color: $text_primary_color;
  margin-right: 55px;
}

.rts-modal-trip {
  text-align: center;
}

.marker-pin-style {
  background-image: url('../../../assets/tripStats/pin.png');
  background-size: 32px 44px;
  background-repeat: no-repeat;
  position: absolute;
  width: 32px;
  height: 44px;
  text-align: center;
  color: $background_color;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
}

.rts-arrow-div {
  text-align: center;
  margin-top: 10px;
}

.toggleContainerLeft {
  margin-bottom: 30px;
  width: 50%;
  display: inline-block;
  padding: 0 0 0 2%;

  .data-view-toggle {
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    min-width: 135px;
    width: max-content;
    margin: 0;
    padding: 0;
    &:focus-within {
      outline: 3px solid $focus_outline_color;
    }
  }

  .data-view-toggle input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }
  .data-view-toggle label {
    background-color: $background_color;
    color: $primary_color;
    font-size: 14px;
    line-height: auto;
    text-align: center;
    padding: 8px 16px;
    margin: 0 -1px 0 0;
    margin-right: -1px;
    border: 1px solid $primary_color;
  }
  .data-view-toggle label:hover {
    cursor: pointer;
  }
  .data-view-toggle input:checked + label {
    background-color: $primary_color;
    color: $background_color;
  }
  .data-view-toggle label:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  .data-view-toggle label:last-of-type {
    border-radius: 0 4px 4px 0;
  }
}

.toggleContainerRight {
  width: 50%;
  display: inline-block;
  padding: 0 0 0 9%;

  .rts-toggle {
    justify-content: center;
    -ms-flex-align: center;
    display: flex;
    display: -ms-flexbox;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .trips-data-viewtype {
    display: -ms-flexbox;
    display: flex;
  }

  .trips-data-viewtype input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
  }

  .trips-data-viewtype input:focus + label .toggleSwitch {
    outline: 3px solid $focus_outline_color;
  }

  .trips-data-viewtype label {
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    font-size: 1em;
    text-align: center;
    color: $primary_color;
    .toggleSwitch {
      margin: 0 8px;
      background-color: $primary_color;
      border-radius: 25px;
      width: 50px;
      height: 24px;
      display: none;
    }
  }

  .trips-data-viewtype label .toggleSwitch .selectedCircle {
    width: 16px;
    height: 16px;
    background-color: $background_color;
    border-radius: 25px;
    display: block;
    margin: 4px;
    margin: 4px 30px 4px 4px;
  }

  .trips-data-viewtype input:checked + label .toggleSwitch {
    display: inline-block;
  }

  .trips-data-viewtype input#r-monthly + label .toggleSwitch .selectedCircle {
    margin: 4px 4px 0 30px;
  }
}

.line-mph-icon {
  width: 178px;
  margin-left: 2vw;
}

.line-mpg-icon {
  width: 178px;
  margin-left: 5vw;
}

.rts-modal-exit {
  background-image: url('../../../assets/tripStats/close.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  float: right;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.rts-stops-modal {
  max-width: 1200px;
}

.graph-avgspeed-summary-fr {
  font-size: 14px;
  font-family: VWText;
}

.graph-avgspeed-summary-en {
  font-size: 16px;
  font-family: VWText;
  margin-left: 2vw;
}

.graph-avgspeed {
  font-size: 25px;
  font-family: VWHead;
  font-weight: bold;
}

.graph-avgfuel-summary-fr {
  font-size: 14px;
  font-family: VWText;
  margin-left: 1vw;
}

.graph-avgfuel-summary-en {
  font-size: 16px;
  font-family: VWText;
  margin-left: 6vw;
}

.summary-no-data {
  margin-left: 5vw;
}

.graph-avgfuel {
  font-size: 25px;
  font-family: VWHead;
  font-weight: bold;
}

.graph-totaldistance-summary {
  font-size: 16px;
  font-family: VWText;
  margin-left: 20px;
}

.graph-totaldistance {
  font-size: 30px;
  font-family: VWHead;
  font-weight: bold;
  margin-right: 5px;
}
.graph-totaltrips-summary {
  font-size: 16px;
  font-family: VWText;
  margin-left: 100px;
}

.graph-totaltrips {
  font-size: 30px;
  font-family: VWHead;
  font-weight: bold;
  margin-right: 5px;
}

.about-driveview-score {
  font-family: VWHead;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 33px;
  text-align: center;
  color: $text_primary_color;
}

.driveview-modal-txt {
  font-family: VWText;
  font-size: 15px;
  margin-bottom: 25px;
  color: $text_primary_color;
}

.driveview-modal-body {
  margin-left: 70px;
  margin-right: 70px;
}

.rts-map-div {
  height: 70vh;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: $primary_color;
}

.rts-nextprev-date {
  margin-left: 75px;
  margin-right: 91px;
  font-size: 16px;
  font-weight: bold;
  color: $text_primary_color;
  font-family: VWHead;
}

.rts-weekly-txt {
  margin-right: 3px;
  font-family: VWText;
  font-size: 16px;
  color: $primary_color;
}

.rts-monthly-txt {
  margin-left: 10px;
  font-family: VWText;
  font-size: 16px;
  color: $primary_color;
}

.rts-permission {
  text-align: center;
  font-size: 16px;
  font-family: VWText;
  color: $text_label_dark_color;
  margin-top: 20px;
}

.rts-driveview-score {
  width: 7%;
  margin: auto;
}

.rts-driveview-icon {
  width: 72px;
  height: 60px;
  margin-top: 28px;
  margin-bottom: 350px;
}

.driveViewNotEnrolled {
  max-width: 1186px;
  height: 480px;
  margin: auto;
  text-align: center;
  padding-top: 20px;
  margin-top: 25px;
}

.rts-ubinotenrolled-txt {
  max-width: 1180px;
  height: 69px;
  font-family: VWHead;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: $text_primary_color;
  margin-bottom: 15px;
  margin-top: 25px;
}

.rts-graph-chart {
  margin-left: 20px;
  margin-right: 25px;
}

.rts-back {
  font-family: VWText;
  font-size: 12px;
  cursor: pointer;
}

.rts-no-score {
  text-align: center;
  max-width: 480px;
  margin: auto;
  padding: 20px 5px 380px;
  img {
    padding-top: 20px;
  }
  &.no-padding {
    padding: 20px 5px 40px 5px;
  }
}

span.rts-nextprev {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

span.rts-nextprev:focus,
div.rts-delete-history:focus,
span.rts-modal-exit:focus,
label.react-switch-label:focus,
button.rts-tripid:focus {
  outline: 3px solid $focus_outline_color;
}

//Responsive CSS
@media (max-width: 767px) {
  .rts-home {
    margin-top: 15px;
    margin-right: 0;
    margin-left: 0;
  }

  .line-mph-icon {
    width: 152px;
    margin-left: 20px;
  }

  .line-mpg-icon {
    width: 152px;
    margin-left: 20px;
  }

  .driview-enroll-btn {
    width: 117px;
    font-size: 12px;
  }

  .driview-btn {
    margin-left: 0px;
    margin-top: -44px;
  }

  .graph-totaltrips-summary {
    margin-left: 50px;
  }

  .feature-icon {
    margin-left: 0px;
  }

  .driveview-modal-body {
    margin-left: 20px;
    margin-right: 20px;
  }

  .rts-arrowicon {
    width: 19px;
    height: 19px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .rts-nextprev-date {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
    font-family: VWText;
  }

  .graph-totaldistance {
    font-size: 20px;
  }

  .graph-totaltrips {
    font-size: 20px;
  }

  .graph-avgspeed {
    font-size: 20px;
  }

  .graph-avgfuel {
    font-size: 20px;
    margin-right: 0px;
  }

  .rts-history-table {
    font-size: 12px;
  }

  .rts-delete-history {
    font-size: 12px;
    font-weight: normal;
  }

  .rts-tripid {
    font-size: 12px;
  }

  .rts-card {
    margin-top: 10px;
  }
}

@media (max-width: 700px) {
  .toggleContainerRight {
    padding: 0 0 0 5%;
  }
}

@media (max-width: 650px) {
  .toggleContainerRight {
    padding: 0 0 0 0;
  }
}

@media (max-width: 500px) {
  .toggleContainerRight {
    padding: 0 0 0 9%;
    .text-nowrap {
      white-space: normal !important;
    }
  }
}

@media (max-width: 380px) {
  .toggleContainerLeft {
    width: 100%;
    display: block;
    padding: 0;
    margin: auto 2%;
  }
  .toggleContainerRight {
    width: 100%;
    display: block;
    padding: 0;
    margin: auto 2%;
  }
}

.gm-style img {
  position: static;
  transform: none;
  margin: auto;
}
