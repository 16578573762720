@import '../../reusable_cmp_lib/colorVariables.scss';
.acct-mgmt-contact-dealer-modal {
  text-align: center;

  .service-modal-urltext {
    margin-left: 5%;
    width: 90%;
    max-width: 90%;
    word-wrap: break-word;
  }

  .gotit-btn {
    margin: auto;
    width: 340px;
    height: 42px;
    border-radius: 24px;
    background-color: $background_color;
    font-size: 16px;
    color: $primary_color;
  }
}
