@import '../../reusable_cmp_lib/colorVariables.scss';

.data-share {
  .drive-your-way {
    margin-left: 10%;
    font-family: VWHead;
    font-size: 25px;
    font-weight: bold;
  }

  .modal-exit {
    background-image: url('../../../assets/tripStats/close.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    float: right;
    width: 23px;
    height: 23px;
    cursor: pointer;
  }

  .modal-body {
    margin-left: 60px;
    margin-right: 60px;
  }

  .radio-btn-text {
    font-family: VWText;
    font-size: 16px;
    color: $carnet_root_color;
  }

  .grid-center {
    display: grid;
    place-items: center;
  }

  .continue-btn {
    font-size: 16px;
    font-family: VWText;
    font-weight: bold;
    color: $button_primary_text;
  }

  .discounted-rates {
    font-size: 12px;
    font-family: VWText;
    color: $text_label_dark_color;
  }
}
