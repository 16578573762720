@import '../../reusable_cmp_lib/colorVariables.scss';
.styles_faq-row-wrapper__3Hsch {
  padding: 64px 160px 90px 160px;
}

.styles_faq-row-wrapper__3Hsch .styles_row-body__8wIE9 .styles_faq-row__2Rd2Y .styles_row-title__2yqnt {
  padding: 25px 0;
}

.faqCAheader {
  font-size: 16px;
  font-family: 'VWTextBold';
}
.mdlyr {
  font-family: 'VWTextBold';
}

.faq-row.styles_faq-row__2Rd2Y {
  padding: 12px;
}

.goback {
  margin: 3rem;
  text-align: left;
  margin-bottom: 0px;
  &:hover {
    cursor: pointer;
  }
  .arrow {
    font-size: 20px;
    color: $carnet_root_color;
  }
  .back {
    font-size: 15px;
    font-family: VWText;
    color: $carnet_root_color;
    margin-left: 8px;
  }
}

.viewFaqLink {
  float: right;
  margin-right: 34px;
}
.viewFaqText {
  height: 16px;
  font-family: VWText;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: $link_text_light_color;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

a {
  color: $link_text_light_color;
}
a:hover {
  color: $link_text_light_color;
}
