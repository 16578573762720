.landingPageWrapper {
  .checkEligiblityBtn {
    margin-bottom: 7px;
    font-size: 1em;
    padding: 12px 32px 12px 32px;
    color: #fff;
    background-color: rgb(0, 30, 80);
    border: none;
    border-radius: 20px;
    &.disabled {
      background-color: #dfe4e8;
    }
  }

  input {
    padding-top: 10px;
    padding-bottom: 5px;
    &:focus {
      outline: none;
      border-color: rgb(0, 30, 80);
    }
  }

  #canv {
    padding-right: 25%;
    color: rgb(0, 30, 80);
    border: 1px solid rgb(0, 30, 80);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  /* Hide reload captcha link text */
  #reload_href {
    display: none;
  }
}
