.cwp-header {
  &.loginOutOverride {
    .logInOut {
      float: left;
    }
  }

  &.langToggleOverride {
    .languageToggle {
      .blueHamburger {
        float: left;
      }
    }
  }

  .wrapperRight {
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }

  .prelogin {
    float: left;
    margin: 21px;
  }

  @media only screen and (min-width: 770px) {
    .wrapperRight {
      margin-bottom: -2px;
    }
  }
}
