@import '../../reusable_cmp_lib/colorVariables.scss';

.duet-date {
  width: 100%;
  .duet-date__dialog {
    width: 100%;
    min-height: 320px;
  }
}

.duet-date__input {
  font-family: 'VWHead';

  border-bottom: 1px solid $inputbox_border;
  &:focus {
    border-bottom: 1px solid $inputbox_border_focused;
  }
  &:invalid:focus {
    border-bottom: 1px solid $remove_item_color;
  }
}

.duet-date__input-wrapper {
  margin: 0;
  padding: 0;
  font-family: 'VWHeadLight';
}

.duet-date__input-wrapper button {
  border: 3px solid transparent;
}

.duet-date__input-wrapper button:focus {
  border: 3px solid $focus_outline_color;
  border-radius: 0px;
  outline: none;
}

.duet-date__input {
  padding: 0 0 8px 0;
}

.duet-date__dialog-content {
  font-family: 'VWHead';
  button {
    border: 3px solid transparent;
  }
  button:focus {
    border: 3px solid $focus_outline_color;
    border-radius: 0px;
  }
  button:disabled {
    border: none;
    border-radius: 0px;
    color: $button_disabled_text;
    font-family: 'VWHeadLight';
  }

  .duet-date__select-label {
    border: 3px solid transparent;
  }
  .duet-date__select select:focus + .duet-date__select-label {
    border: 3px solid $focus_outline_color;
  }
  .duet-date__day.is-month.is-outside {
    color: $button_disabled_text;
    font-family: 'VWHeadLight';
  }
}

.duet-date__dialog.is-active {
  margin-top: 10px;
  width: 100%;
  min-width: 200px;
  height: 340px;
  display: block;
  z-index: 900;
  position: absolute;
}

@media screen and (max-width: 575px) {
  .duet-date__dialog.is-active {
    margin-top: 100px;
    width: 100%;
    min-width: 200px;
    height: 340px;
    display: block;
    z-index: 900;
    position: absolute;
  }
}
