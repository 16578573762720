@import '../../reusable_cmp_lib/colorVariables.scss';
div.garageVehicles {
  width: 100%;
  max-width: 1250px;
  margin: auto;
  background-color: transparent;
  display: inline-block;
}

div.garageVehicles.vehicleWrapper {
  width: 100%;
  display: inline-block;
}

div.vehicleInfo {
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  border-radius: 4px;
  text-align: left;
  margin: auto;
  margin-top: 20px;
  min-height: 150px;
  display: block;
  padding: 10px 10px 10px 10px;
  flex: none;
  width: 100%;
  max-width: calc(100% - 20px);
}

.actionHookProfileCompletionModal {
  .buttonWrapper {
    width: 100%;
    padding: 0 10%;
    display: flex;
    justify-content: space-around;
    button {
      text-align: center;
      width: auto;
      min-width: 150px;
      margin: 0 5px;
    }
  }
  .h4 {
    font-size: 0.5em;
  }
}

@media only screen and (min-width: 600px) {
  div.garageVehicles.vehicleWrapper {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  div.vehicleInfo {
    background-position: center;
    width: calc(50% - 40px);
    margin: 20px;
    min-height: 200px;
    display: block;
    float: left;
    padding: 20px 20px 20px 20px;
    flex: 1;
    -ms-flex: 1;
    max-width: 40%;
    width: 40%;
    min-width: 40%;
  }
  div.verizon3gSunset {
    margin: 38px 360px 32px;
  }
}
div.vehicleInfo span,
div.vehicleInfo h6 {
  display: inline-block;
}

div.vehicleInfo .vehicleName {
  cursor: pointer;
  font-size: 24px;
  line-height: 1em;
  margin-bottom: 5px;
}

.pinForm {
  max-width: 300px;
  margin: auto;
  text-align: center;
}

div.downSizedh2 h2 {
  font-size: 20px;
}

.vehicleWrapper {
  position: inherit;
}

.vehicleOptions {
  display: inline-block !important;
  width: 100%;
  margin-bottom: 150px;
  position: relative;
  text-align: right;
}

div.verizon3gSunset {
  background-color: $box_border_sides_color;
  font-family: VWHead;
  color: $text_primary_color;
}

div.verizon3gSunsetMessage {
  padding: 16px 20px;
  font-size: 12px;
}
