@import '../../reusable_cmp_lib/colorVariables.scss';

.helptopic-ev {
  position: absolute;
  top: 12vw;
  left: 7%;
}

.centered-ev {
  transform: translate(-49%, -170px);
  float: right;
}

.power-text-ev {
  margin-top: -45px;
  color: white;
  font-size: 11px;
  text-align: center;
}

.rectangle-ev {
  width: 100%;
  text-align: center;
  background-image: radial-gradient(circle at -22% -21%, #00437a, #001e50 60%);
  margin-top: -46px;
  height: 420px;
}

.rectangle-plus-ev {
  width: 100%;
  text-align: center;
  background-image: radial-gradient(circle at -22% -21%, #00437a, #001e50 60%);
  margin-top: -17px;
  height: 445px;
}

.signup-ev {
  margin-bottom: 60px;
}
.help-on {
  margin-top: -2px;
}

.googleplay {
  height: 45px;
  width: 174px;
  margin-left: 17px;
}

.rectangletd-ev {
  vertical-align: top;
  height: 219px;
  padding-top: 30px;
}
.active-page-ev {
  color: #00b0f0 !important;
}

.spanrectangle-ev {
  margin-bottom: 15px;
}

.rectangleDesc-ev {
  font-size: 20px;
  color: white;
  text-align: center;
  margin: 0 auto;
}

.rectangletd-ev {
  vertical-align: top;
  height: 219px;
  padding-top: 30px;
}

.top-left-ev {
  position: absolute;
  margin-top: -20%;
  left: 105px;
  text-align: left;
}

.img-hotspot-ev {
  margin-left: 85%;
  margin-top: -19%;
}

.top-left-driving-ev {
  position: absolute;
  margin-top: -20%;
  left: 105px;
}

.cwplandingPage-ev {
  background-color: #fff;
  border-style: none;
  color: #001e50;
  font-size: 1rem;
  line-height: 1rem;
  border-radius: 22px;
  outline: none;
  width: 169px;
  height: 44px;
  left: 145%;
  position: relative;
  top: -14px;
}

.at-your-server-ev {
  margin-top: -46px;
}
.landingnavbarEV {
  overflow: hidden;
  position: relative;
  top: 72px;
  width: 90%;
  z-index: 1;
  margin-left: 165px;
}
.seg-3 {
  margin-top: -2px;
}
.hotspot-on {
  margin-top: -46px;
}

.suplanding-ev,
.suplanding-ev-8,
.suplanding-ev-7,
.suplanding-ev-5,
.suplanding-ev-4 {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  font-size: 10px;
  line-height: 15px;
  color: #001e50;
  background: #fff;
}
.suplanding-ev-7 {
  padding-left: 4px;
  top: -3px;
  left: -1px;
}
.suplanding-ev {
  padding-left: 0px;
  top: -34px;
}
.suplanding-ev-8 {
  top: -5px;
  left: 0px;
  padding-left: 2px;
}
.suplanding-ev-5 {
  padding-left: 0px;
  top: -34px;
  left: 5px;
}
.suplanding-ev-4 {
  float: right;
  top: -9.3vw;
  left: -13vw;
  padding-left: 5px;
}
.ev-res-2 {
  height: 387px;
  text-align: center;
  background-image: radial-gradient(circle at -22% -21%, #00437a, #001e50 60%);
  margin-top: -151px;
  width: 100%;
}
.at-your-server-res-ev {
  margin-top: -202px;
}
.plusNav-res-ev {
  margin-top: -2px;
}
.ev-res-5 {
  width: 99.7%;
  height: 386px;
  text-align: center;
  background-image: radial-gradient(circle at -22% -21%, #00437a, #001e50 60%);
  margin-top: 145px;
}
.ev-res-4 {
  margin-top: -202px;
}
.ev-res-8 {
  margin-top: -281px;
  margin-bottom: -275px;
}
.ev-res-7 {
  margin-top: 192px;
}
.ev-res-3,
.ev-res-6 {
  margin-top: -2px;
}
.enjoy-en-route-bold-res-ev {
  font-size: 20px;
  color: #fff;
  font-family: 'VWTextbold';
  padding-right: 12px;
}
.enjoy-en-route-light-res-ev {
  font-size: 20px;
  color: #fff;
  line-height: 34px;
  font-family: 'VWHeadLight';
}
